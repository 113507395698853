// const func = ({promo, index, activity}) => {};
// const func = props => {}; /// props = {}
import constants from "../../components/constants";
import Card from 'react-bootstrap/Card';
import { BsInstagram, BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';

// const styles = {
//   textNormal : {
//     textDecoration: 'none'
//   },
//   textStrikeThrough: {
//     textDecoration: 'line-through'
//   }
// }

const PromoListItem = ({promo}) => {
  
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  if (!promo) return null;

  


  return (
    <>
      
      <div className="app__gallery-images_card flex__center" >
        
      {/* <Card style={{ width: '18rem' }}> */}
              <img 
              src={promo.avatar ? `${constants.ENDPOINT}/${promo.avatar}` : ''} alt="gallery_image" />
              
              <i class="bi bi-box-arrow-up-left gallery__image-icon" onClick={handleShow}></i>
              {/* <Card.Body>
              <Card.Title>{promo.promoname}</Card.Title>
        <Card.Text>
        {promo.description}
        </Card.Text>
        </Card.Body> */}
        
            </div>


     {/* ======= */}

     

      <Modal show={show} onHide={handleClose} className="bg-transparent">
        {/* <Modal.Header className="sidebarbg" closeButton>

          
        </Modal.Header> */}
        
        

              <img className="promo__gallery-images_card"
              src={promo.avatar ? `${constants.ENDPOINT}/${promo.avatar}` : ''} alt="gallery_image"  />
              <BsInstagram href="#" className="gallery__image-icon " />
              
              
              
              <Modal.Body className="sidebarbg">
              <a href="#" className="p__cormorant">{promo.promoname}</a>
                <h3 className="p__opensans">{promo.description}</h3>
                <p className="text-white ">(Validity Period, Terms & Conditions Applied.)</p>
                </Modal.Body>
              
        
        
        {/* <Modal.Footer>
          
        </Modal.Footer> */}
      </Modal>

     

    </>
  );
};

export default PromoListItem;