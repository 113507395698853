import React, { useEffect, useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import constants from '../constants';


function Navbar1({Toggle}) {

  const [promos, setPromos] = useState([]);
  const [finishedCount, setFinishedCount] = useState(0);
  const [unfinishedCount, setUnfinishedCount] = useState(0);
  const [reservations, setReservations] = useState([]);
  const [finishedCountR, setFinishedCountR] = useState(0);
  const [unfinishedCountR, setUnfinishedCountR] = useState(0);

  useEffect(() => {
    // console.log(constants.ENDPOINT);
    fetch(`${constants.ENDPOINT}/api/promos`, {method: 'GET', mode: 'cors'})
      .then(response => response.json())
      .then(data => {
        setPromos(data.data);
      })
      .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    // console.log(constants.ENDPOINT);
    fetch(`${constants.ENDPOINT}/api/reservations`, {method: 'GET', mode: 'cors'})
      .then(response => response.json())
      .then(data => {
        setReservations(data.data);
      })
      .catch(error => console.error(error));
  }, []);

  const refreshReservations = () => {
    fetch(`${constants.ENDPOINT}/api/reservations`, {
      method: 'GET',
      mode: 'cors'
    })
    .then(response => response.json())
    .then(data => {
      setReservations([...data.data]);
    })
    .catch(error => console.error(error));
  };
  const refreshPromos = () => {
    fetch(`${constants.ENDPOINT}/api/promos`, {
      method: 'GET',
      mode: 'cors'
    })
    .then(response => response.json())
    .then(data => {
      setPromos([...data.data]);
    })
    .catch(error => console.error(error));
  };

  

  useEffect(() => {
    setFinishedCount(promos.filter(promo => promo.activity).length);
    setUnfinishedCount(promos.filter(promo => !promo.activity).length);
  }, [promos]);

  useEffect(() => {
    setFinishedCountR(reservations.filter(reservation => reservation.completed).length);
    setUnfinishedCountR(reservations.filter(reservation => !reservation.completed).length);
  }, [reservations]);

  return (
    <>
    {/* <nav className="navbar navbar-expand-sm navbar-dark bg-dark zindex-popover" >
    
    </nav> */}

{/* <!-- Navbar --> */}
<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  {/* <!-- Container wrapper --> */}
  <div class="container-fluid">
    {/* <!-- Toggle button --> */}
    <button
      class="navbar-toggler"
      type="button"
      data-mdb-toggle="collapse"
      data-mdb-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <i class="fas fa-bars"></i>
    </button>

    {/* <!-- Collapsible wrapper --> */}
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      {/* <!-- Navbar brand --> */}
      <i className='navbar-brand bi bi-layout-sidebar-inset fs-4'onClick={Toggle}></i>
    <button className='navbar-toggler d-lg-none' type="button" data-bs-toggle="collapse"
    data-bs-target="#collapsibleNavId" aria-expanded="false" aria-label="Toggle navigation">
        
    </button>
      {/* <!-- Left links --> */}
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" href="#">Admin Panel</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#"></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#"></a>
        </li>
      </ul>
      {/* <!-- Left links --> */}
    </div>
    {/* <!-- Collapsible wrapper --> */}

    {/* <!-- Right elements --> */}
    <div class="d-flex align-items-center">
      {/* <!-- Icon --> */}
      <a class="text-reset me-3" href="#" 
      onClick={() => refreshReservations()}
      >
      <i class="fa-solid fa-clipboard-list  text-white"></i>
      <span class="badge badge-notification bg-primary fa-bounce " >{unfinishedCountR}</span>
    </a>
      <a class="text-reset me-3" href="#"
      onClick={() => refreshPromos()}
      >
        <i class="fa-solid fa-rectangle-ad text-white"></i>
        <span class="badge badge-notification bg-primary fa-bounce">{finishedCount}</span>
      </a>

      {/* <!-- Notifications --> */}
      {/* <div class="dropdown">
        <a
          class="text-reset me-3 dropdown-toggle hidden-arrow"
          href="#"
          id="navbarDropdownMenuLink"
          role="button"
          data-mdb-toggle="dropdown"
          aria-expanded="false"
        >
          <i class="fa-solid fa-rectangle-ad text-white"></i>
          <span class="badge badge-notification bg-primary">{unfinishedCount}</span>
        </a>
        <ul
          class="dropdown-menu dropdown-menu-end"
          aria-labelledby="navbarDropdownMenuLink"
        >
          <li>
            <a class="dropdown-item" href="#">Some news</a>
          </li>
          <li>
            <a class="dropdown-item" href="#">Another news</a>
          </li>
          <li>
            <a class="dropdown-item" href="#">Something else here</a>
          </li>
        </ul>
      </div> */}

      {/* <!-- Avatar --> */}
      <div class="dropdown">
        <a
          class="dropdown-toggle d-flex align-items-center hidden-arrow"
          href="#"
          id="navbarDropdownMenuAvatar"
          role="button"
          data-mdb-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
            class="rounded-circle"
            height="25"
            alt="Black and White Portrait of a Man"
            loading="lazy"
          />
        </a>
        <ul
          class="dropdown-menu dropdown-menu-end"
          aria-labelledby="navbarDropdownMenuAvatar"
        >
          <li>
            <a class="dropdown-item" href="#">My profile</a>
          </li>
          <li>
            <a class="dropdown-item" href="#">Settings</a>
          </li>
          <li>
            <a class="dropdown-item" href="#">Logout</a>
          </li>
        </ul>
      </div>
    </div>
    {/* <!-- Right elements --> */}
  </div>
  {/* <!-- Container wrapper --> */}
</nav>
{/* <!-- Navbar --> */}




</>
  )
}

export default Navbar1