import React from 'react';
// import { FiFacebook, FiTwitter, FiInstagram } from 'react-icons/fi';

import { Menu2Overlay, Menu2Box } from '../../components';
// import { images } from '../../constants';
import './Menu2.css';

const Menu2 = () => (
  <div className="app__footer section__padding" >
    <Menu2Overlay />
    <Menu2Box />

    

  </div>
);

export default Menu2;
