import { useState } from "react";


const PromoSearch = ({searchPromo, refreshPromos}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    searchPromo(searchPromo ? searchTerm : '$$$RESTORE_CACHE$$$');
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="input-group">
      <input type="text" 
        className="form-control" 
        placeholder="search promos..."
        value={searchTerm} 
        onChange={(e) => {
          if(e.target.value == '') {
            refreshPromos();
            setSearchTerm('');
          } else {
            setSearchTerm(e.target.value);
          }
        }}
      />
      <button type="submit" className="btn btn-sm btn-info custom__buttonsearch">
        <i className="fa-solid fa-magnifying-glass"></i>
      </button>
      </div>
    </form>
  );
};

export default PromoSearch;