import { useState } from "react";


const ReservationSearch = ({searchReservation, refreshReservations}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    searchReservation(searchReservation ? searchTerm : '$$$RESTORE_CACHE$$$');
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="input-group ">
      <input type="text" 
        className="form-control custom__search" 
        placeholder="search reservations..."
        value={searchTerm} 
        onChange={(e) => {
          if(e.target.value === '') {
            refreshReservations();
            setSearchTerm('');
          } else {
            setSearchTerm(e.target.value);
          }
        }}
      />
      <button type="submit" className="btn btn-sm btn-info custom__buttonsearch">
        <i className="fa-solid fa-magnifying-glass"></i>
      </button>
      </div>
    </form>
  );
};

export default ReservationSearch;