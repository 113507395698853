import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import { Outlet, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import mainlogo from '../src/assets/mainlogowhite.png';
import mainlogovid from '../src/assets/mainlogowhite.mp4';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import images from './constants/images';



const App = () => {

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  
  const [loggedIn, setLoggedIn] = useState(false);
  const [toggleMenu, setToggleMenu] = React.useState(false);

  useEffect(() => {
    const auth = localStorage.getItem('AUTH');
    setLoggedIn(auth === 'LOGGED_IN');
  });


  return (
    // localStorage.setItem, localStorage.getItem
    
    

    <div >
      {/* { loggedIn ? 
      ( */}

        <nav className="app__navbar sticky-top">
        <div className="app__navbar-logo">
          <Link to="/login">
          {/* <video autoPlay loop src={mainlogovid} alt="app__logo" /> */}
          <img src={mainlogo} />
          </Link>
        </div>
        <ul className="app__navbar-links">
          <li className="p__opensans"><Link to="/home" onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>Home</Link></li>
          <li className="p__opensans"><a href="/home#about">About</a></li>
          
          <li className="p__opensans"><a href="/home#awards">Awards</a></li>
          <li className="p__opensans"><a href="/home#contact">Contact</a></li>
          <div />
          <li className="p__opensans"><Link to="/Menu" onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>Menu</Link></li>
        </ul>
        <div className="app__navbar-login">
          <Link to="/bookings_reservations" className="p__opensans" onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>Make Reservations</Link>
          <div />
          < Link to="/token" className="p__opensans" onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>Check Reservations</Link>
        </div>
        <div className="app__navbar-smallscreen">
          <GiHamburgerMenu color="#fff" fontSize={27} onClick={() => setToggleMenu(true)} />
          {toggleMenu && (
            <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
              <MdOutlineRestaurantMenu fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
              <ul className="app__navbar-smallscreen_links">
                <li><Link to ="/home" onClick={() => setToggleMenu(false)}>Home</Link></li>
  
                <li><a href="/home#about" onClick={() => setToggleMenu(false)}>About</a></li>
                <li><a href="/home#awards" onClick={() => setToggleMenu(false)}>Awards</a></li>
                <li><a href="/home#contact" onClick={() => setToggleMenu(false)}>Contact</a></li>
                
                <li><Link to="/menu" onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>Menu</Link></li>
                <li><Link to="/bookings_reservations" onClick={() => setToggleMenu(false)}>Make Reservations</Link></li>
                <li><Link to="/token" onClick={() => setToggleMenu(false)}>Check Reservations</Link></li>
              </ul>                                                                                                                         
            </div>
          )}
        </div>
      </nav>
        
        {/* ) : null } */}
      

    <div id="outlet" className='h-100' >
      <Outlet />
    </div>
    </div>
  );
}

export default App;