import { useState } from "react";
import { Link, redirect, useNavigate } from "react-router-dom";
import './Login.css';
import constants from "../constants";
import React from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBIcon
}
from 'mdb-react-ui-kit';
import meal from "../../assets/meal.mp4";
import images from '../../constants/images';



const Login = () => {
  const [errorMessages, setErrorMessage] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const renderErrorMessage = (error) => {
    return (
      <div className="error">{error}</div>
    );
  }

  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
  
    const body = {
      username: event.target['username'].value,
      password: event.target['password'].value
    };
    // console.log(constants.ENDPOINT);
    fetch(`${constants.ENDPOINT}/api/auth/login`, {method: 'POST', mode: 'cors', headers: {
      "Content-Type": "application/json",
    }, body: JSON.stringify(body)})
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if(data.success) {
          localStorage.setItem('AUTH', 'LOGGED_IN');
          navigate('/promo');
        } else {
          localStorage.setItem('AUTH', '');
          alert('Invalid login.')
        }
      })
      .catch(error => console.error(error));
  }

  return (

    <div className="sidebarbg h-100">
    <MDBContainer fluid >

<MDBRow className='d-flex justify-content-center align-items-center h-100'>
  <MDBCol col='12'>

    <MDBCard className='bg-dark text-white my-5 mx-auto' style={{maxWidth: '400px'}}>
      <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>
      <img style={{ maxWidth: '200px'}}src={images.mainlogowhite} alt="app__logo" />
        <h2 className="fw-bold mb-2 text-uppercase"></h2>
        <p className="text-white-50 mb-5">Please enter your login and password!</p>
        <p className="p__opensans">Username</p>
        <MDBInput wrapperClass='mb-4 mx-5 w-100' labelClass='text-dark' label='' id='formControlLg' type='text' size="lg"/>
        <p className="p__opensans">Password</p>
        <MDBInput wrapperClass='mb-4 mx-5 w-100' labelClass='text-dark' label='' id='formControlLg' type='password' size="lg"/>

        <p className="small mb-3 pb-lg-2"><a class="text-white-50" href="#!">Forgot password?</a></p>
        <MDBBtn outline className='mx-2 px-5' color='white' size='lg'>
          <Link to={`/admin`} type="submit" className="bg-transparent">Log in</Link>
          {/* * Display error messages here */}
          {renderErrorMessage(errorMessages)}
        </MDBBtn>
        

        <div className='d-flex flex-row mt-3 mb-5'>
          <MDBBtn tag='a' color='none' className='m-3' style={{ color: 'white' }}>
            <MDBIcon fab icon='facebook-f' size="lg"/>
          </MDBBtn>

          <MDBBtn tag='a' color='none' className='m-3' style={{ color: 'white' }}>
            <MDBIcon fab icon='twitter' size="lg"/>
          </MDBBtn>

          <MDBBtn tag='a' color='none' className='m-3' style={{ color: 'white' }}>
            <MDBIcon fab icon='google' size="lg"/>
          </MDBBtn>
        </div>

        <div>
          <p className="mb-0">Don't have an account? <a href="#!" class="text-white-50 fw-bold">Sign Up</a></p>

        </div>
      </MDBCardBody>
    </MDBCard>

  </MDBCol>
  
</MDBRow>

</MDBContainer>
    

</div>
  );
};

export default Login;