import { useState } from "react";


import Modal from 'react-bootstrap/Modal';
import { Container, Col, Row, Form, Button } from "react-bootstrap";

const PromoCreate = ({addPromo}) => {
  const [description, setDescription] = useState('');
  const [promoname, setPromoname] = useState('');
  const [avatar, setAvatar] = useState('');
  const [promos, setPromos]=useState([]);
 

  const handleSubmit = (e) => {
    console.log(e.target);
    e.preventDefault(); // dont refresh the action
    if (!description) return;
    const data = {
     avatar: e.target.formFile.files[0],
     promoname: promoname,
    description: description,
    activity: false
      
    };
    
    console.log(data);
    addPromo(data);
    setDescription('');
    setPromoname('');
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button className="custom__button" onClick={handleShow}>
        Create A Promo
        <i class="fa-regular fa-square-plus px-2"></i>
      </Button>
      

    {/* modal */}


      <Modal className="center-modal t-25" show={show} onHide={handleClose}>
      <Modal.Header className="sidebarbg" closeButton>
          <Modal.Title className="p__cormorant" >Create News or Promotions</Modal.Title>
        </Modal.Header>
      <form encType="multipart/form-data" className="bg-dark" onSubmit={handleSubmit}>
        
        <Modal.Body>
          <Form.Group className="p__opensans" controlId="promo_name">
              <Form.Label className="p__opensans">Promo Name</Form.Label>
              <Form.Control
                type="text" id="" 
                onChange={(e) => {
                setPromoname(e.target.value);
                 }} value={promoname}
                autoFocus
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="description">
              <Form.Label className="p__opensans">Description</Form.Label>
              <Form.Control
                type="text" id="" 
                onChange={(e) => {
                setDescription(e.target.value);
                 }} value={description}
                autoFocus
              />
            </Form.Group>

            <Form.Group controlId="formFile" className="mb-3">
            <Form.Label className="p__opensans">Poster Image</Form.Label>
            <Form.Control type="file" name="avatar" placeholder="Select an image" autoFocus  />
             </Form.Group>
            

        </Modal.Body>
        <Modal.Footer>
          <Button className="custom__button" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="custom__button" type="submit" onClick={handleClose} >
            Save Changes
          </Button>
        </Modal.Footer>
        </form>
      </Modal>
      
   
    </>
  );
};

export default PromoCreate;