import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import constants from '../constants';


const ReservationCreate = (reservation) => {
    const [FullName, setFullName] = useState('');
    const [PartySize, setPartySize] = useState('');
    const [EmailAddress, setEmailAddress] = useState('');
    const [ContactNumber, setContactNumber] = useState('');
    const [Date, setDate] = useState('');
    const [Time, setTime] = useState('');
    const [Message, setMessage] = useState('');
    const [PreorderedSetMeal, setPreorderedSetMeal] = useState('');
    const [Occasion, setOccasion] = useState('');
    const [reservations, setReservations] = useState([]);
  
  const addReservation = (reservation) => {
    fetch(`${constants.ENDPOINT}/api/reservations`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(reservation)
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        const newReservations = [...reservations];
        newReservations.push(data.data);
        setReservations(newReservations);
      } else {
        alert('There was an issue adding this reservation.');
      }
    })
    .catch(error => console.error(error));
  };  

  const handleSubmit = (e) => {
    e.preventDefault(); // dont refresh the action
   
    const data = {
      
      FullName: FullName,
      Message: Message,
      PartySize: PartySize,
      EmailAddress: EmailAddress,
      ContactNumber: ContactNumber,
      PreorderedSetMeal: PreorderedSetMeal,
      Occasion: Occasion,
      Date: Date,
      Time: Time,
      completed: false
    };
    console.log(data);
    addReservation(data);
    
    setFullName('');
    setPartySize('');
    setEmailAddress('');
    setContactNumber('');
    setPreorderedSetMeal('');
    setOccasion('');
    setMessage('');
    setDate('');
    setTime('');
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

        <Button className="custom__button" onClick={handleShow}>
        Create Reservation
        <i class="bi bi-calendar2-plus px-2"></i>
      </Button>

    <Modal className="center-modal" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create A Reservation</Modal.Title>
          </Modal.Header>
            <Form method='post' className="bg-dark" onSubmit={handleSubmit}>
              <Modal.Body>
                                <Row className="mb-3 ">
                                    <Form.Group controlId="full_name">
                                        <Form.Label className="p__opensans">Full Name</Form.Label>
                                            <Form.Control type="text" id="" 
                                            onChange={(e) => {
                                              setFullName(e.target.value);
                                            }} value={FullName} placeholder="Enter Your Full Name..." autoFocus required/>
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} className="" controlId="email_address">
                                            <Form.Label className="p__opensans">Email Address</Form.Label>
                                                <Form.Control type="email"  id="" value={EmailAddress} 
                                                onChange={(e) => {
                                                setEmailAddress(e.target.value);
                                                }}
                                                placeholder="Ex. youremail@gmail.com" autoFocus required />
                                    </Form.Group>
                                    
                                    <Form.Group as={Col} controlId="contact_number">
                                        <Form.Label className="p__opensans">Contact Number</Form.Label>
                                              <Form.Control type="text" id="" value={ContactNumber} 
                                                  onChange={(e) => {
                                                    setContactNumber(e.target.value);
                                                  }} placeholder="Ex. 0929455033"/>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} className="mb-3" controlId="party_size">
                                        <Form.Label className="p__opensans">Party Size</Form.Label>
                                            <Form.Control type="text" id="" autoFocus required value={PartySize} 
                                            onChange={(e) => {
                                              setPartySize(e.target.value);
                                            }} 
                                            placeholder="Ex. 3" />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="Occasion">
                                        <Form.Label className="p__opensans">Occasion(optional):</Form.Label>
                                            <Form.Select autoFocus value={Occasion} 
                                                      onChange={(e) => {
                                                        setOccasion(e.target.value);
                                                      }} defaultValue="">
                                                      <option>None</option>
                                                      <option>Breakfast</option>
                                                      <option>Lunch</option>
                                                      <option>Dinner</option>
                                                      <option>Birthday</option>
                                                      <option>Meeting</option>
                                                      <option>Romantic Date</option>
                                                      <option>Wedding Anniversary</option>
                                            </Form.Select>
                                    </Form.Group>
                                </Row>
                                
                                <Row className="mb-3 ">
                                <Form.Group as={Col} controlId="preodered_Set_Meal">
                                        <Form.Label className="p__opensans" >Preorder a Set Meal(optional):</Form.Label>
                                                <Form.Select autoFocus value={PreorderedSetMeal} 
                                                      onChange={(e) => {
                                                        setPreorderedSetMeal(e.target.value);
                                                      }} >
                                                      <option>None</option>
                                                      <option>Meat Lover</option>
                                                      <option>SeaFood Lover</option>
                                                      <option>Doubled</option>
                                                      <option>Combo</option>
                                                </Form.Select>
                                    </Form.Group>
                                  </Row>
                                  <Row className="mb-3 ">
                                <Form.Group controlId="Message">
                                        <Form.Label className="p__opensans">Additional Request or Service</Form.Label>
                                        <Form.Control as="textarea" rows={3}
                                        value={Message} 
                                        onChange={(e) => {
                                        setMessage(e.target.value);
                                        }} placeholder="Additional Request or Service" autoFocus />
                                </Form.Group>
                                </Row>
                                
                                
                                <Row className="mb-3">
                                <Form.Group as={Col} controlId="Date">
                                    <Form.Label className="p__opensans">Date:</Form.Label>
                                    <Form.Control type="date" 
                                    className="form-control" 
                                    autoFocus
                                    required
                                    value={Date} 
                                    onChange={(e) => {
                                      setDate(e.target.value);
                                    }}  />
                                </Form.Group>
                                <Form.Group as={Col} controlId="Time">
                                        <Form.Label className="p__opensans" >Time:</Form.Label>
                                                <Form.Select autoFocus value={Time} 
                                                required
                                                      onChange={(e) => {
                                                        setTime(e.target.value);
                                                      }} >
                                                        <option defaultValue={null}>Choose..</option>
                                                      <option>8:00 AM</option>
                                                      <option>10:00 AM</option>
                                                      <option>12:00 PM</option>
                                                      <option>2:00 PM</option>
                                                      <option>4:00 PM</option>
                                                      <option>6:00 PM</option>
                                                      <option>8:00 PM</option>
                                                      <option>9:30 PM</option>
                                                </Form.Select>
                                    </Form.Group>
                                    </Row>
                                  <br></br>
                                  </Modal.Body>
                                  <Modal.Footer>
                                <row className="d-flex mb-3 justify-content-center p__opensans">      
                                  <Button className="custom__button"  type="submit" id="" onClick={handleClose}>
                                      Submit
                                  </Button>
                                </row>  
                                </Modal.Footer>
                                <br></br> 
                        </Form>
      </Modal>

    </>
  );
};

export default ReservationCreate;