import React, { useEffect, useState } from 'react';
import constants from '../../components/constants';
import { Container, Col, Row, Form, Button, Carousel, } from "react-bootstrap";
import { SubHeading } from '../../components';
import { images } from '../../constants';

function BasicExample() {
  const [tokenid, setTokenid] = useState('');
  const [reservation, setReservation] = useState({});

 
  

  const GetRD = (reservation) => {
    fetch(`${constants.ENDPOINT}/api/reservations/${reservation}`, {
      method: 'GET',
      mode: 'cors'
    })
    .then(response => response.json())
    .then(data => {
      if(data.success){
      setReservation(data.data);
      }
      else {
        alert('Invalid Reference ID!');
      }
    })
    .catch(error => console.error(error));
  
  };


  const handleSubmit = (e) => {
    console.log(e.target);
    e.preventDefault(); // dont refresh the action
    
    // const tokenid = {
    //   tokenid:tokenid
      
    // };
    
    console.log(tokenid);
    GetRD(tokenid);
    setTokenid('');
    
  };

  const renderRefreshBtn = () => {
    return (
      <a 
        href="#!" 
        className='btn-primary'
        onClick={() => refreshReservations()}
        >
          <i className="fa-solid fa-arrows-rotate test-white"></i>
      </a>
    );
  };

  const refreshReservations = () => {
    fetch(`${constants.ENDPOINT}/api/reservations/${tokenid}`, {
      method: 'GET',
      mode: 'cors'
    })
    .then(response => response.json())
    .then(data => {
      setReservation([...data.data]);
    })
    .catch(error => console.error(error));
  };

  return (
    <>


  


  <div className="app__specialMenu flex__center section__padding" id="menu">
    
    <div className="app__specialMenu-title">
      <SubHeading title="Check Reservation" />
      <h1 className="headtext__cormorant">Reservations</h1>
    </div>

    <div className="app__specialMenu-menu">
      <div className="app__specialMenu-menu_wine  flex__center" style={{marginTop:"0"}}>
        <p className="app__specialMenu-menu_heading">Enter Your Reference ID</p>
        <div className="app__specialMenu_menu_items">
          <div className="app__wrapper_info  ">
      <SubHeading  title="Ref. ID:" />
      {/* <h1 className="headtext__cormorant" style={{ marginBottom: '3rem' }}></h1> */}
      <div className="app__wrapper-content ">
      <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        {/* <Form.Label>asd</Form.Label> */}
        <Form.Control type="text" 
        onChange={(e) => {setTokenid(e.target.value);}} 
        value={tokenid}
        placeholder="Enter Ref. ID" />
        <Form.Text className="text-muted">
          (Keep your Ref. ID private.)
        </Form.Text>
      </Form.Group>

      
      <button type="submit" className="custom__button" style={{ marginTop: '2rem' }}>Get</button>
    </Form>

      </div>
      
    </div>
        </div>
      </div>

      <div className="app__specialMenu-menu_img">
      <img src={images.findus} alt="location_img" />
      </div>

      <div className="app__specialMenu-menu_cocktails  flex__center" style={{marginTop:"0"}}>
        <p className="app__specialMenu-menu_heading">Reservation Details</p>
        <div className="app__specialMenu_menu_items">
        <div className="app__wrapper_info">
        <Row className='justify-content-between'>
          <Col>
        <SubHeading title="Details " /> 
        </Col>
        <Col>
        <div className='p__opensans'>
        {renderRefreshBtn()}
        </div>
        </Col>
      </Row>
      {/* <h1 className="headtext__cormorant" style={{ marginBottom: '3rem' }}></h1> */}
      <div className="app__wrapper-content">
      <Form>
      
      <p className='p__opensans'>Full Name: {reservation.FullName}</p><p className='p__opensans'></p>
      <p className='p__opensans'>Date: {reservation.Date}</p><p className='p__opensans'></p>
      <p className='p__opensans'>Time: {reservation.Time}</p><p className='p__opensans'></p>
      <p className='p__opensans'>PartySize: {reservation.PartySize}</p><p className='p__opensans'></p>
      <p className='p__opensans'>EmailAddress: {reservation.EmailAddress}</p><p className='p__opensans'></p>
      <p className='p__opensans'>ContactNumber: {reservation.ContactNumber}</p><p className='p__opensans'> </p>
      <p className='p__opensans'>PreorderedSetMeal: {reservation.PreorderedSetMeal}</p><p className='p__opensans'></p>
      <p className='p__opensans'>Occasion: {reservation.Occasion}</p><p className='p__opensans'></p>
      <p className='p__opensans'>Message: {reservation.Message}</p><p className='p__opensans'></p>
     
      
      {/* <button type="submit" className="custom__button" style={{ marginTop: '2rem' }}>Copy</button> */}
    </Form>

      </div>
      
    </div>
        </div>
      </div>
    </div>

    {/* <div style={{ marginTop: 15 }}>
      <button type="button" className="custom__button">View More</button>
    </div> */}
  </div>

    
    
    
    
    
    
    
    </>
  );
}

export default BasicExample;