import React, { useState } from 'react';
import './Admin.css';
import Sidebar from './Sidebar';
import Home from './Home';
// import { GiTruce } from 'react-icons/gi';




function Admin() {
  const [toggle,setToggle] = useState(true)
  const Toggle = () => {
      setToggle(!toggle)
  }


  
  return (
    <div className='container-fluid bg-dark min-vh-100'>
      <div className='row '>
        {toggle && <div className='col-4 col-md-2 bg-white vh-100 '>
        <Sidebar />
        </div> }
        {/* {toggle && <div className='col-4 col-md-2'></div>} */}
        <div className='col'>
        
        {/* <Home Toggle={Toggle} /> */}
        </div>
        </div>
       </div>
  )
}

export default Admin