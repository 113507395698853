import images from './constants/images';

const menu = [
    {
        id: 1,
        title: 'Creamy Carbonara',
        category: 'Meat & Seafood',
        price: 299,
        price2: 359,
        imgUrl: images.creamycarbonara,
        desc: `Linguine pasta, bacon & mushroom, white sauce, parmesan cheese.`,
      },
      {
        id: 2,
        title: 'Spaghetti Meatballs',
        category: 'Meat & Seafood',
        price: 249,
        price2: 459,
        imgUrl: images.spaghettimeatballs,
        desc: `Spaghetti pasta, meatballs, marinara sauce, and parmesan cheese.`,
      },
      {
        id: 3,
        title: 'Seafood Pesto',
        category: 'Meat & Seafood',
        price: 319,
        price2: 539,
        imgUrl: images.seafoodpesto,
        desc: `Linguine pasta, garlic shrimp, squid & crab, pesto cream sauce, chopped parsley.`,
      },
      {
        id: 4,
        title: 'Scampi Shrimp',
        category: 'Meat & Seafood',
        price: 299,
        price2: 519,
        imgUrl: images.scampishrimp,
        desc: `Linguine pasta, shrimp, specially made creamy white sauce, parmesan cheese, chopped parsley.`,
      },
      {
        id: 5,
        title: 'Chilli Garlic Shrimp',
        category: 'Meat & Seafood',
        price: 249,
        price2: 459,
        imgUrl: images.chiligarlicshrimp,
        desc: `Spaghetti pasta , garlic & shrimp, special garlic sauce, chopped parsley.`,
      },
      {
        id: 6,
        title: 'Keto Chicken Salad',
        category: 'Meat & Seafood',
        price: 229,
        price2: 359,
        imgUrl: images.ketochickensalad,
        desc: `Chicken breast, mayonnaise, crispy bacon, spring onion, red onion, celery, salt & pepper, lettuce.`,
      },
      {
        id: 7,
        title: 'Spaghetti Pesto',
        category: 'Vegetarian',
        price: 219,
        price2: 399,
        imgUrl: images.spaghettipesto,
        desc: `Spaghetti sauce, garlic in olive oil, pesto sauce, parmesan cheese.`,
      },
      {
        id: 8,
        title: 'Creamy Penne',
        category: 'Vegetarian',
        price: 219,
        price2: 399,
        imgUrl: images.creamypenne,
        desc: `Penne pasta, chopped mushroom, creamy white sauce, and parmesan cheese.`,
      },
      {
        id: 9,
        title: 'Mac n Cheese',
        category: 'Vegetarian',
        price: 319,
        price2: 539,
        imgUrl: images.macncheese,
        desc: `Macaroni pasta mixed creamy cheese sauce, and grated parmesan cheese with basil on top. `,
      },
      {
        id: 10,
        title: 'Caesar Salad',
        category: 'Vegetarian',
        price: 199,
        price2: 259,
        imgUrl: images.caesarsalad,
        desc: `Lettuce, tomato, crispy bacon, boiled egg, croutons caesar dressing, parmesan cheese.`,
      },
      {
        id: 11,
        title: 'Fish & Chips',
        category: 'fingerfoods',
        price: 249,
        price2: 459,
        imgUrl: images.fishchips,
        desc: `Marinated fish fillet dipped in batter mix and coated with bread crumbs and dipped fried with fries on the side.`,
      },
      {
        id: 12,
        title: 'Onion Rings',
        category: 'fingerfoods',
        price: 179,
        price2: 259,
        imgUrl: images.onionrings,
        desc: `White onions coated with specially made batter mix & bread crumbs dip with our very own version of ranch dressing on the side.`,
      },
      {
        id: 13,
        title: 'Corn Taco',
        category: 'fingerfoods',
        price: 159,
        price2: 259,
        imgUrl: images.corntaco,
        desc: `Corn sautéed in onions and sour cream stuffed in taco shells topped with chopped tomato, onions & cheese.`,
      },
      {
        id: 14,
        title: 'Chicken Quesadilla',
        category: 'fingerfoods',
        price: 239,
        price2: 459,
        imgUrl: images.quesadilla,
        desc: `Marinated chicken fillet stuffed in flour tortilla with grated cheese.`,
      },
      {
        id: 15,
        title: 'Chicken Finger',
        category: 'fingerfoods',
        price: 239,
        price2: 359,
        imgUrl: images.chickenfinger,
        desc: `Marinated chicken breast dipped in batter mix & coated with bread crumbs and dipped fried and fries on the side.`,
      },
      {
        id: 16,
        title: 'Chef Calamari',
        category: 'fingerfoods',
        price: 239,
        price2: 359,
        imgUrl: images.chefscalamari,
        desc: `Squid ring coated with specially made breading, batter mix & bread crumbs dip in marinara sauce & cheese on top.`,
      },
      {
        id: 17,
        title: 'Chicken Taco',
        category: 'fingerfoods',
        price: 229,
        price2: 359,
        imgUrl: images.chickentaco,
        desc: `Marinated chicken fillet stir fry and stuffed in taco shells topped with chopped onion and pineapple.`,
      },
      {
        id: 18,
        title: 'Cheesy Nachos',
        category: 'fingerfoods',
        price: 229,
        price2: 359,
        imgUrl: images.cheesynachos,
        desc: `Nachos with cooked ground beef, tomatoes, onion drizzled with specially made cheese sauce on top.`,
      },
  ];
  export default menu;