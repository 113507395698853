import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import 'bootstrap-icons/font/bootstrap-icons.css'
import Home from './Home';
import ReservationList from '../ReservationList/ReservationList';
import PromoList from '../PromoList/PromoList';
import Navbar from './Nav';
import { Outlet, Link } from 'react-router-dom';

function Sidebar() {
  const [toggle,setToggle] = useState(true)
  const Toggle = () => {
      setToggle(!toggle)
  }

  return (
    <>
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
    <div className='container-fluid bg-dark min-vh-100 '>
    <div className='row sidebarbg '> 
      <nav className='hidenav'>
        <Row className='p-2'>
          
              <Col>
              <Nav.Link eventKey="first" >
              <i className='bi bi-speedometer2 fs-5 me-3 '></i>
              </Nav.Link>
              </Col>
              <Col>
              <Nav.Link eventKey="second" >
              <i className='fa-solid fa-clipboard-list fs-5 me-3 '></i>
              </Nav.Link>
              </Col>
              <Col>
              <Nav.Link eventKey="third" >
              <i className='fa-solid fa-rectangle-ad fs-5 me-3 '></i>
              </Nav.Link>
              </Col>
              <Col>
              <Nav.Link eventKey="fourth" >
              <i className='fa-solid fa-store fs-5 me-3 '></i>
              </Nav.Link>
              </Col>
              <Col>
              <Nav.Link eventKey="fifth" >
              <i className='fa-solid fa-chart-column fs-5 me-3 '></i>
              </Nav.Link>
              </Col>
              <Col>
              <Nav.Link eventKey="" >
              <Link to ="/home">
              <i className='bi bi-power fs-5 me-3 '></i>
              </Link>
              </Nav.Link>
              </Col>
              
              </Row>
      </nav>
    
      {toggle && <div className=' sidebarW col-4 col-md-2 sidebarbg vh-100 '>
      
    <div className='bg-transparent sidebar p-2 v-100 sidebarW'> 
          <Nav variant="pills" className="flex-column   ">
          <Nav.Link eventKey="" className='list-group-item py-2 sidebarW '>
              <i className='bi bi-house-gear-fill fs-3 me-3 '></i>
            <span className='textdisappear fs-3 '  >Admin</span>
              </Nav.Link>
        <hr className='text-white sidebarcontentW '></hr>
            
        <div className='list-group list-group-flush sidebarcontentW'>
              <Nav.Link eventKey="first" className='list-group-item py-2'>
              <i className='bi bi-speedometer2 fs-5 me-3'></i>
            <span className='textdisappear'  >Dashboard</span>
              </Nav.Link>
              <Nav.Link eventKey="second" className='list-group-item py-2'>
              <i className='fa-solid fa-clipboard-list fs-5 me-3'></i>
            <span className='textdisappear'>Reservations</span>
              </Nav.Link>
              <Nav.Link eventKey="third" className='list-group-item py-2'>
              <i className='fa-solid fa-rectangle-ad fs-5 me-3'></i>
            <span className='textdisappear'>Promotions</span>
              </Nav.Link>
              <Nav.Link eventKey="fourth" className='list-group-item py-2'>
              <i className='fa-solid fa-store fs-5 me-3'></i>
            <span className='textdisappear' >Marketing</span>
              </Nav.Link>
              <Nav.Link eventKey="fifth" className='list-group-item py-2'>
              <i className='fa-solid fa-chart-column fs-5 me-3'></i>
            <span className='textdisappear' >Sales</span>
              </Nav.Link>
              
              
              <Nav.Link href="/home" eventKey="sixth" className='list-group-item py-2'>
              <Link to ="/home">
              <i className='bi bi-power fs-5 me-3'></i>
            <span className='textdisappear' >Logout</span>
            </Link>
              </Nav.Link>
              
            </div>
          </Nav>
        
        
     
        
    </div>
    
      </div> }
      
      <Col sm={10} className='bg-dark sidebarcontent'>
      <div className='col'>
      <Navbar></Navbar>
      {/* <Home Toggle={Toggle} /> */}
      
          <Tab.Content >
            
            <Tab.Pane eventKey="first">
            (Dito yung mga charts and graphs)
            </Tab.Pane>

            <Tab.Pane eventKey="second">
            <ReservationList />
              </Tab.Pane>

              <Tab.Pane eventKey="third">
            <PromoList />
              </Tab.Pane>


          </Tab.Content>
          </div>
        </Col>

      
      </div>
     </div>



    


    </Tab.Container>

     </>
  )
}

export default Sidebar