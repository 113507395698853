import bg from '../assets/bg.png';
import chef from '../assets/chef.png';
import G from '../assets/G.png';
import gallery01 from '../assets/gallery01.png';
import gallery02 from '../assets/gallery02.png';
import gallery03 from '../assets/gallery03.png';
import gallery04 from '../assets/gallery04.png';
import gallery05 from '../assets/gallery05.png';
import gallery06 from '../assets/gallery06.png';
import knife from '../assets/knife.png';
import logo from '../assets/logo.png';
import menu from '../assets/menu.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import welcome from '../assets/welcome.png';
import findus from '../assets/findus.png';
import laurels from '../assets/laurels.png';
import award01 from '../assets/award01.png';
import award02 from '../assets/award02.png';
import award03 from '../assets/award03.png';
import award05 from '../assets/award05.png';
import sign from '../assets/sign.png';
import quote from '../assets/quote.png';
import mainlogowhite from '../assets/mainlogowhite.png';
import italianmexican from '../assets/italianmexican.png';
import caesarsalad from '../assets/caesarsalad.png';
import cheesemarinara from '../assets/cheesemarinara.png';
import cheesynachos from '../assets/cheesynachos.png';
import chefscalamari from '../assets/chefscalamari.png';
import chickenfinger from '../assets/chickenfinger.png';
import chickentaco from '../assets/chickentaco.png';
import chiligarlicshrimp from '../assets/chiligarlic.png';
import corntaco from '../assets/corntaco.png';
import creamycarbonara from '../assets/creamycarbonara.png';
import creamypenne from '../assets/cheesemarinara.png';
import fishchips from '../assets/fishchips.png';
import ketochickensalad from '../assets/ketochickensalad.png';
import macncheese from '../assets/macncheese.png';
import onionrings from '../assets/onionrings.png';
import quesadilla from '../assets/quesadilla.png';
import scampishrimp from '../assets/scampishrimp.png';
import seafoodpesto from '../assets/seafoodpesto.png';
import spaghettimeatballs from '../assets/spaghettimeatballs.png';
import spaghettipesto from '../assets/spaghettipesto.png';


export default {
  bg,
  chef,
  G,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  gallery05,
  gallery06,
  knife,
  logo,
  menu,
  overlaybg,
  spoon,
  welcome,
  findus,
  laurels,
  award01,
  award02,
  award03,
  award05,
  sign,
  quote,
  mainlogowhite,
  italianmexican,
  caesarsalad,
  cheesemarinara,
  cheesynachos,
  chefscalamari,
  chickenfinger,
  chickentaco,
  chiligarlicshrimp,
  corntaco,
  creamycarbonara,  
  creamypenne,
  fishchips,
  ketochickensalad,
  macncheese,
  onionrings,
  quesadilla,
  scampishrimp,
  seafoodpesto,
  spaghettimeatballs,
  spaghettipesto,
};
