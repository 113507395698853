import React from 'react';

import { SubHeading, MenuItem } from '../../components';
import { data, images } from '../../constants';
import './SpecialMenu.css';
import menuparallax1 from '../../assets/menuparallax1.jpg'
import { Parallax } from 'react-parallax'
// import { Parallax, Background } from 'react-parallax'

const SpecialMenu = () => (
  <div>
    <div className='app__bg'>
  <Parallax bgImage={menuparallax1} strength={500}>
      <div style={{ height: 700 }}>
        {/* <div style={insideStyles}>HTML inside the parallax</div> */}
      </div>
    </Parallax>
    </div>
  <div className="app__specialMenu flex__center section__padding" id="menu">
    
    <div className="app__specialMenu-title">
      <SubHeading title="Menu that fits your palatte" />
      <h1 className="headtext__cormorant">Set Meals Special</h1>
    </div>

    <div className="app__specialMenu-menu">
      <div className="app__specialMenu-menu_wine  flex__center">
        <p className="app__specialMenu-menu_heading">Meat or Seafood?</p>
        <div className="app__specialMenu_menu_items">
          {data.meat_seafood.map((meat_seafood, index) => (
            <MenuItem key={meat_seafood.title + index} title={meat_seafood.title} price={meat_seafood.price} tags={meat_seafood.tags} tags2={meat_seafood.tags2} tags3={meat_seafood.tags3} tags4={meat_seafood.tags4} />
          ))}
        </div>
      </div>

      <div className="app__specialMenu-menu_img">
        <img src={images.menu} alt="menu__img" />
      </div>

      <div className="app__specialMenu-menu_cocktails  flex__center">
        <p className="app__specialMenu-menu_heading">Doubled or Combo?</p>
        <div className="app__specialMenu_menu_items">
          {data.doubled_combo.map((doubled_combo, index) => (
            <MenuItem key={doubled_combo.title + index} title={doubled_combo.title} price={doubled_combo.price} tags={doubled_combo.tags} tags2={doubled_combo.tags2} tags3={doubled_combo.tags3} tags4={doubled_combo.tags4}/>
          ))}
        </div>
      </div>
    </div>

    {/* <div style={{ marginTop: 15 }}>
      <button type="button" className="custom__button">View More</button>
    </div> */}
  </div>
  
  </div>
);

export default SpecialMenu;
