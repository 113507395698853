// const func = ({promo, index, activity}) => {};
// const func = props => {}; /// props = {}
import constants from "../constants";
import PromoEdit from "./PromoEdit";
import React, { useEffect, useState } from 'react';
import PromoEdit2 from "./PromoEdit2";

import Modal from 'react-bootstrap/Modal';
import { Container, Col, Row, Form, Button } from "react-bootstrap";







const PromoListItem = ({promo, deletePromo, completeOrRestorePromo, editPromo}) => {
  
  const [activity, setActivity] = useState(false);
  const [promoname, setPromoname] = useState('');
  const [selectedPromo, setSelectedPromo] = useState({});
  const [description, setDescription] = useState('');
  
  
  

  const handleSubmit = (e) => {
    e.preventDefault(); //dont refresh
    console.log('Form Update hit.');
    
    
    const data = {
      avatar: e.target.formFile.files[0],
      id: promo.id,
      promoname: promoname, 
      description: description,
      
      activity: activity
    };
    console.log(data);
    editPromo(data);
    
    setDescription('');
    setPromoname('');
   
  };
  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (!promo) return null;
  return (
    <>
      <tr className="fw-normal" key={promo.id}>
      <th className="align-middle" style={{padding: '0px 5px'}}>
          <div className="d-flex justify-content-between ps-3">
            <a href="#!" 
            className=""
              data-mdb-toggle="tooltip" 
              title="Activate or Deactivate"
              onClick={() => completeOrRestorePromo(promo)}>
              <i className={`${promo.activity ? 'fa-solid fa-power-off' : 'fa-solid fa-arrow-up-from-bracket' }`}></i>
              </a>
            
            <a 
              href="#!"  
              data-mdb-toggle="tooltip" 
              title="Select to edit"
              onClick={() => {
                editPromo(promo);
                // handleShow();
              }}
              >
                <i className="bi bi-pencil-square text-warning"></i>
                
                
            </a>


            {/* <Modal className="center-modal" show={show} onHide={handleClose}>
      
      <Modal.Header closeButton>
          <Modal.Title>
          <h5 className="modal-title" id="taskEditLabel">Edit: {promo.promoname}</h5>
          </Modal.Title>
        </Modal.Header>
      <form encType="multipart/form-data" onSubmit={handleSubmit}>
        
        <Modal.Body>

        
          
            
            <Form.Group className="mb-3" controlId="promo_name">
              <Form.Label>Promo Name</Form.Label>
              <Form.Control
                type="text" id="" 
                onChange={(e) => {
                setPromoname(e.target.value);
                 }} value={promoname}
                autoFocus
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text" id="" 
                onChange={(e) => {
                setDescription(e.target.value);
                 }} value={description}
                autoFocus
              />
            </Form.Group>

            <Form.Group controlId="formFile" className="mb-3">
            <Form.Label></Form.Label>
            <Form.Control type="file" name="avatar"  />
             </Form.Group>
            

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button  variant="primary" type="submit" onClick={handleClose} >
            Save Changes
          </Button>
        </Modal.Footer>
        </form>
      
      </Modal> */}

            
       
            <a 
              href="#!" 
              data-mdb-toggle="tooltip" 
              title="Delete"
              onClick={() => deletePromo(promo.id)}
              >
              <i className="fas fa-trash-alt text-danger"></i>
            </a>
          </div>

        </th>
        <td>
          <img src={ promo.avatar ?`${constants.ENDPOINT}/${promo.avatar}` : ''}
            className="shadow-1-strong " alt="poster_image"
            style={{width:"10rem", height: "auto"}} /> 
          
        </td>
        <td className="align-middle">
        <span className="ms-2">{promo.promoname}</span>
        </td>
        <td className="align-middle">
          <span 
            >
              {promo.description}
          </span>
        </td>
        
        
      </tr>
    </>
  );
};

export default PromoListItem;