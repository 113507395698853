import images from './images';

const menu = [
  {
    id: 1,
    title: 'Creamy Carbonara',
    category: 'meatseafood',
    price: 299,
    price2: 359,
    imgUrl: images.creamycarbonara,
    desc: `Linguine pasta, bacon & mushroom, white sauce, parmesan cheese.`,
  },
  {
    id: 2,
    title: 'Spaghetti Meatballs',
    category: 'meatseafood',
    price: 249,
    price2: 459,
    imgUrl: images.spaghettimeatballs,
    desc: `Spaghetti pasta, meatballs, marinara sauce, parmesan cheese.`,
  },
  {
    id: 3,
    title: 'Seafood Pesto',
    category: 'meatseafood',
    price: 319,
    price2: 539,
    imgUrl: images.seafoodpesto,
    desc: `Linguine pasta, garlic shrimp, squid & crab, pesto cream sauce, chopped parsley.`,
  },
  {
    id: 4,
    title: 'Scampi Shrimp',
    category: 'meatseafood',
    price: 299,
    price2: 519,
    imgUrl: images.scampishrimp,
    desc: `Linguine pasta, shrimp, specially made creamy white sauce, parmesan cheese, chopped parsley.`,
  },
  {
    id: 5,
    title: 'Chilli Garlic Shrimp',
    category: 'meatseafood',
    price: 249,
    price2: 459,
    imgUrl: images.chiligarlicshrimp,
    desc: `Spaghetti pasta , garlic & shrimp, special garlic sauce, chopped parsley.`,
  },
  {
    id: 6,
    title: 'Keto Chicken Salad',
    category: 'meatseafood',
    price: 229,
    price2: 359,
    imgUrl: images.ketochickensalad,
    desc: `Chicken breast, mayonnaise, crispy bacon, spring onion, red onion, celery, salt & pepper, lettuce.`,
  },
  {
    id: 7,
    title: 'Spaghetti Pesto',
    category: 'veggie',
    price: 219,
    price2: 399,
    imgUrl: images.spaghettipesto,
    desc: `Spaghetti sauce, garlic in olive oil, pesto sauce, parmesan cheese.`,
  },
  {
    id: 8,
    title: 'Creamy Penne',
    category: 'veggie',
    price: 219,
    price2: 399,
    imgUrl: images.creamypenne,
    desc: `Penne pasta, mushroom, white sauce, parmesan cheese.`,
  },
  {
    id: 9,
    title: 'Mac n Cheese',
    category: 'veggie',
    price: 319,
    price2: 539,
    imgUrl: images.macncheese,
    desc: `Macaroni pasta, creamy cheese sauce, parmesan cheese.`,
  },
  {
    id: 10,
    title: 'Caesar Salad',
    category: 'veggie',
    price: 199,
    price2: 259,
    imgUrl: images.caesarsalad,
    desc: `Lettuce, tomato, crispy bacon, boiled egg, croutons caesar dressing, parmesan cheese.`,
  },
  {
    id: 11,
    title: 'Fish & Chips',
    category: 'fingerfoods',
    price: 249,
    price2: 459,
    imgUrl: images.fishchips,
    desc: `Marinated fish fillet dipped in batter mix and coated with bread crumbs and dipped fried with fries on the side.`,
  },
  {
    id: 12,
    title: 'Onion Rings',
    category: 'fingerfoods',
    price: 179,
    price2: 259,
    imgUrl: images.onionrings,
    desc: `White onions coated with specially made batter mix & bread crumbs dip with our very own version of ranch dressing on the side.`,
  },
  {
    id: 13,
    title: 'Corn Taco',
    category: 'fingerfoods',
    price: 159,
    price2: 259,
    imgUrl: images.corntaco,
    desc: `Corn sautéed in onions and sour cream stuffed in taco shells topped with chopped tomato, onions & cheese.`,
  },
  {
    id: 14,
    title: 'Chicken Quesadilla',
    category: 'fingerfoods',
    price: 239,
    price2: 459,
    imgUrl: images.quesadilla,
    desc: `Marinated chicken fillet stuffed in flour tortilla with grated cheese.`,
  },
  {
    id: 15,
    title: 'Chicken Finger',
    category: 'fingerfoods',
    price: 239,
    price2: 359,
    imgUrl: images.chickenfinger,
    desc: `Marinated chicken breast dipped in batter mix & coated with bread crumbs and dipped fried and fries on the side.`,
  },
  {
    id: 16,
    title: 'Chef Calamari',
    category: 'fingerfoods',
    price: 239,
    price2: 359,
    imgUrl: images.chefscalamari,
    desc: `Squid ring coated with specially made breading, batter mix & bread crumbs dip in marinara sauce & cheese on top.`,
  },
  {
    id: 17,
    title: 'Chicken Taco',
    category: 'fingerfoods',
    price: 229,
    price2: 359,
    imgUrl: images.chickentaco,
    desc: `Marinated chicken fillet stir fry and stuffed in taco shells topped with chopped onion and pineapple.`,
  },
  {
    id: 18,
    title: 'Cheesy Nachos',
    category: 'fingerfoods',
    price: 229,
    price2: 359,
    imgUrl: images.cheesynachos,
    desc: `Nachos with cooked ground beef, tomatoes, onion drizzled with specially made cheese sauce on top.`,
  },
  
  
];

const meat_seafood = [
  {
    title: 'Meat Lover',
    price: 'P1,119',
    tags: '1 Carbonara | Family',
    tags2: '1 Chicken Taco',
    tags3: '1 Keto Salad',
    tags4: '1 Chicken Finger',
  },
  {
    title: 'Seafood Lover',
    price: 'P1,119',
    tags: '1 Scampi Shrimp | Family',
    tags2: '1 Caesar Salad',
    tags3: "1 Chef's Calarami",
    tags4: '1 Fish & Chips',
  },
  
];

const doubled_combo = [
  {
    title: 'Doubled',
    price: 'P859',
    tags: '1 Seafood Pesto | Share',
    tags2: '1 Chicken Taco',
    tags3: '1 Keto Salad',
    tags4: '1 Fish & Chips',
  },
  {
    title: 'Meat & Seafood Combo',
    price: 'P1,099',
    tags: '1 Spaghetti Bolognese | Family',
    tags2: '1 Caesar Salad',
    tags3: '1 Chicken Quesadilla',
    tags4: '1 Onion Rings',
  },
  
];

const awards = [
  {
    imgUrl: images.award02,
    title: 'Best Italian-Mexican Fusion Restaurant',
    subtitle: 'Awarded for the exceptional blending of Italian and Mexican flavors, creating a unique culinary experience.',
  },
  {
    imgUrl: images.award01,
    title: 'Best Pasta Selection',
    subtitle: "Acknowledging the restaurant's mastery in creating a wide variety of homemade pastas, ranging from classic Italian styles to innovative Mexican-infused variations.",
  },
  {
    imgUrl: images.award05,
    title: 'Most Creative Menu',
    subtitle: "Recognizing the restaurant's ability to craft inventive and enticing dishes that marry the best elements of Italian and Mexican culinary traditions.",
  },
  {
    imgUrl: images.award03,
    title: 'Outstanding Chef',
    subtitle: "Awarded for the Chef's Remarkable Skill and Expertise as Head Cook in a World Class Cruise Ship.",
  },
];

export default { meat_seafood, doubled_combo, awards, menu };
