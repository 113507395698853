import React from 'react';

import SubHeading from '../SubHeading/SubHeading';
import './Menu2Box.css';
import italianmexican from '../../assets/italianmexican.png'

const Menu2Box = () => (
  <div className="menu2boxborder">
    <div className="menu2boxborder-heading">
      <SubHeading title="Neighbor Chef's" />
      <h1 className="headtext__cormorant">The Menu</h1>
      
      <img className="italianmexicanpic" src={italianmexican}></img>
    
    </div>
    
  </div>
);

export default Menu2Box;
