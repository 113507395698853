import './PromoList.css';
import PromoListItem from './PromoListItem';
import React, { useEffect, useState } from 'react';
import constants from '../constants';
import PromoCreate from './PromoCreate';
import PromoSearch from './PromoSearch';
import PromoEdit from "./PromoEdit";
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';




const PromoList = () => {
  // variable
  const [promos, setPromos] = useState([]);
  const [selectedPromo, setSelectedPromo] = useState({});
  const [finishedCount, setFinishedCount] = useState(0);
  const [unfinishedCount, setUnfinishedCount] = useState(0);
  
  

  const fuzzySearch = function (query) {
    const str = this.toLowerCase();
    let i = 0, n = -1, l;
    query = query.toLowerCase();
    for (; l = query[i++] ;){
      if (!~(n = str.indexOf(l, n + 1))){
          return false;
      };
    };
    return true;
  };
  String.prototype.fuzzySearch = fuzzySearch;

  useEffect(() => {
    // console.log(constants.ENDPOINT);
    fetch(`${constants.ENDPOINT}/api/promos`, {method: 'GET', mode: 'cors'})
      .then(response => response.json())
      .then(data => {
        setPromos(data.data);
      })
      .catch(error => console.error(error));
  }, []);


  useEffect(() => {
    setFinishedCount(promos.filter(promo => promo.activity).length);
    setUnfinishedCount(promos.filter(promo => !promo.activity).length);
  }, [promos]);

  // helper function
  const getIndexById = (id) => promos.indexOf(promos.filter((promo) => promo.id === id)[0]);

  // delete promo: (1) update the parent array, (2) call api to delete on backend
  const deletePromo = (id) => {

    fetch(`${constants.ENDPOINT}/api/promos/${id}`, {
      method: 'DELETE', 
      mode: 'cors',
      headers: {
        'Authorization' : `Basic ${btoa('admin:adminpass')}`
      }})
    .then(response => response.json())
    .then(data => {
      if(data.success === true && data.data.recordsAffected === 1) {
        console.log(id);
        // 1. Make your own copy of data
        const newPromos = [...promos]; //copy [1,2,3]
        // 2. Modify your copy data
        newPromos.splice(getIndexById(id), 1);
        // 3. Replace the original data
        setPromos(newPromos);
        alert('Rerservation deleted succesfully.');
      } else {
        alert(`Unable to delete promo id=${id}`)
      }
    })
    .catch(error => console.error(error));
  }

  // refresh promo
  const refreshPromos = () => {
    fetch(`${constants.ENDPOINT}/api/promos`, {
      method: 'GET',
      mode: 'cors'
    })
    .then(response => response.json())
    .then(data => {
      setPromos([...data.data]);
    })
    .catch(error => console.error(error));
  };

  // toggle activity flag
  const completeOrRestorePromo = (promo) => {
    fetch(`${constants.ENDPOINT}/api/promos/${promo.id}`, {
      method: 'PUT',
      mode: 'cors', 
      headers: {
        'Content-Type' : 'application/json'
      },
      body: JSON.stringify({
        promoname: promo.promoname,
        description: promo.description,
        activity: !promo.activity
      })
    })
    .then(response => response.json())
    .then(data => {
      if(data.success) {
        const newPromos = [...promos];
        newPromos[getIndexById(promo.id)].activity = !promo.activity;
        setPromos(newPromos);
      } else {
        alert('There was an issue completing/restoring this promo.');
      }
    })
    .catch(error => console.error(error));
  };

// add promo
  const addPromo = (promo) => {
    const data = { 
      description: promo.description,
      promoname: promo.promoname,
      activity: promo.activity
       }
    fetch(`${constants.ENDPOINT}/api/promos`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {

        const formData = new FormData();
        formData.append('avatar', promo.avatar )
            fetch(`${constants.ENDPOINT}/api/promos/${data.data.id}/avatar`, {
                method: 'POST',
                mode: 'cors',
                body: formData
            })
            .then(response => response.json())
            .then(data => {
              if (data.success) {
                const newPromos = [...promos];
                newPromos.push(data.data.entity);
                setPromos(newPromos);
              } else {
                alert('There was an issue adding this promo.');
              }
            })
            .catch(error => console.error(error));
          }
          else {
            alert('There was an issue adding this promo.');
          }
      
    })
    .catch(error => console.error(error));
  };  

    // search promo
  const searchPromo = (term) => {
    // alert( `Searching for ${term}`)
    const newPromos = [...promos];
    const result = newPromos.filter(promo => promo.promoname.fuzzySearch(term));
    console.log(result);
    setPromos(result);
  };

    // // edit 2

    // const editPromo = (promo) => {
    //   const data = { 
    //     description: promo.description,
    //     promoname: promo.promoname,
    //     activity: promo.activity
    //      }
    //   fetch(`${constants.ENDPOINT}/api/promos/${promo.id}`, {
    //     method: 'PUT',
    //     mode: 'cors',
    //     headers: {
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify(data)
    //   })
    //   .then(response => response.json())
    //   .then(data => {
    //     if (data.success) {
    //       const newPromos = [...promos];
    //        newPromos[getIndexById(promo.id)] = data.data.entity;
    //        setPromos(newPromos);
    //       const formData = new FormData();
    //       formData.append('avatar', promo.avatar )
    //           fetch(`${constants.ENDPOINT}/api/promos/${data.data.id}/avatar`, {
    //               method: 'POST',
    //               mode: 'cors',
    //               body: formData
    //           })
    //           .then(response => response.json())
    //           .then(data => {
    //             if (data.success) {
    //               const newPromos = [...promos];
    //               newPromos[getIndexById(promo.id)] = data.data.entity;
    //               setPromos(newPromos);
    //             } else {
    //               alert('There was an issue adding this promo.');
    //             }
    //           })
    //           .catch(error => console.error(error));
    //         }
    //         else {
    //           alert('There was an issue adding this promo.');
    //         }
        
    //   })
    //   .catch(error => console.error(error));
    // };  
  
    // edit 
    const editPromo = (promo) => {
      const data = { 
        description: promo.description,
        promoname: promo.promoname,
        activity: promo.activity,
        
         }
        fetch(`${constants.ENDPOINT}/api/promos/${promo.id}`, {
        method: 'PUT',
        mode: 'cors', 
        headers: {
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(data => {
        if(data.success) {
          const newPromos = [...promos];
          newPromos[getIndexById(promo.id)] = data.data.entity;
          setPromos(newPromos);
          const formData = new FormData();
          formData.append('avatar', promo.avatar );
          fetch(`${constants.ENDPOINT}/api/promos/${data.data.id}/avatar`, {
            method: 'POST',
            mode: 'cors',
            body: formData
        })
              .then(response => response.json())
              .then(data => {
                if(data.success) {
                  const newPromos = [...promos];
                  newPromos[getIndexById(promo.id)] = data.data.entity;
                  setPromos(newPromos);  
                  alert('Promo edited succesfully.'); 
                } else {
                  alert('There was an issue updating avatar for this promo.');
                }
              })
              .catch(error => console.error(error));
            }
           else {
            alert('There was an issue completing/restoring this promo.');
          }
        })
        .catch(error => console.error(error));
        
      };


  // styles
  const styles = {
    body: {
      backgroundColor : '#eee'
    },
    cardBody: {
      position: 'relative',
      height: '300px',
      overflow: 'auto'
    }
  }

  // helpers
  const renderTableHeading = () => {
    return (
      <thead>
        <tr>
        <th scope="col">Actions</th>
          <th scope="col">Poster</th>
          <th scope="col">Promo Name</th>
          <th scope="col">Description</th>
          
          
        </tr>
      </thead>
    );
  };

  const renderRefreshBtn = () => {
    return (
      <a 
        href="#"
        onClick={() => refreshPromos()}
        >
          <i className="fa-solid fa-arrows-rotate px-2 test-white"></i>
      </a>
    );
  };

  const renderPromoListItem = (promo) => {
    return (<PromoListItem 
      key={promo.id}
      promo={promo} 
      deletePromo={deletePromo}
      editPromo={setSelectedPromo}
      completeOrRestorePromo={completeOrRestorePromo}
    />);
  }

  return (
    
      <>
      <div className="row d-flex justify-content-center align-items-center h-100 ">
            <div >

              <div className="">
                <div className="card-header p-3 bg-dark">
                <div className='d-flex justify-content-between'>
                  <div>
                    <h5 className="mb-0 p__cormorant"><i className="fa-solid fa-rectangle-ad me-2 "></i> News & Promotions {renderRefreshBtn()}</h5>
                  </div>
                  
                  
                  <div>
                  <PromoCreate addPromo={addPromo} /> <nbsp></nbsp>
                  <PromoEdit 
                    promo={selectedPromo}
                    editPromo={editPromo}/>
                      
                    </div>
                  </div>
                </div>

               
                <div className="card-header p-3 bg-dark">
                <div className='d-flex justify-content-between'>
                  <div>
                    <h5 className="mb-0 p__opensans"><i className=" me-2"></i>Active Promos ({finishedCount})</h5>
                  </div>
                  <div>
                  <PromoSearch searchPromo={searchPromo} refreshPromos={refreshPromos} />
                  
                  </div>
                  </div>
                  
                </div>
                
                <div className="bg-light" data-mdb-perfect-scrollbar="true" style={styles.cardBody}>
                  
                  {/* Completed Reservations */}
                  

                  {finishedCount <= 0 ? (<p className="text-center">No items to show</p>) : 
                  (<MDBTable striped>
                      {renderTableHeading()}
                      <tbody >
                        {
                          promos
                          .filter(promo => promo.activity)
                          .map(promo => renderPromoListItem(promo))
                      }
                        
                      </tbody>
                    </MDBTable>
                  )}</div>
                
                

                <div className="card-header p-3 bg-dark">
                <div className='d-flex justify-content-between'>
                  <div>
                    <h5 className="mb-0 p__opensans"><i className=" me-2"></i>Inactive Promos ({unfinishedCount})</h5>
                  </div>
                  
                  <div>
                  <PromoEdit 
                    promo={selectedPromo}
                    editPromo={editPromo}/>
                  </div>
                  </div>
                </div>
                
                <div className="bg-light" data-mdb-perfect-scrollbar="true" style={styles.cardBody}>
                 
                  {/* Incomplete Reservations */}
                  {unfinishedCount <= 0 ? 
                  (<p className="text-center">No items to show</p>) : 
                  (<MDBTable striped>
                      {renderTableHeading()}
                      <tbody>
                        {
                         promos
                         .filter(promo => !promo.activity)
                         .map(promo => renderPromoListItem(promo))
                        }
                      </tbody>
                    </MDBTable>
                  )}
                </div>
                

                
              </div>

            </div>
          </div>
        
        
        

        {/* main section */}
    
        

        {/* main section */}
    
        </>                   
  );

  // unreachable
};


export default PromoList;