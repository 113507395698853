import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReservationList from './components/ReservationList/ReservationList';
import ReservationListItem from './components/ReservationList/ReservationListItem';
import ErrorView from './components/ErrorView/ErrorView';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Login from './components/Login/Login';
import { AboutUs, Chef, FindUs, Footer, Gallery, Header, Intro, Laurels, SpecialMenu, Menu2 } from './container';
import { Navbar } from './components';
import './App.css';
import MainMenu from './MainMenu';
import Gallery2 from './container/Gallery2/Gallery';
import PromoList from './components/PromoList/PromoList';
import AdminPage from './components/Admin/Admin';
import ReservationForm from './container/Form/Form';
import Dashboard from './components/Dashboard/Dashboard';
import Sidebar from './components/Admin/Sidebar';
import PromoGallery from './container/Gallery2/PromoGallery';
import Token from './container/Token/Token';
import SetMeals from './container/Menu/Setmealsform';


const router = createBrowserRouter([
    {
      path: '/',
      element: <App />,
      // errorElement: <ErrorView />,
      children: [
        {
          path: '/login',
          element: <Login />
        },

        {
            path: '/home',
            element: <div>
                
            <Header />
            <AboutUs />
            <Intro />
            <Chef />
            <Gallery />
            <Laurels />
            <FindUs />
            <Footer />
            </div>
        },

        {
            path: '/menu',
            element : 
                <div>
                    
                    <Gallery2 />
                    <SpecialMenu />
                    <Menu2 />
                <MainMenu />
                <Gallery />
                
                
                <Footer />
                </div>  
                
        },
        {
          path: '/reservation',
          element: <ReservationList />
        },

        // {
        //   path: '/admin',
        //   element: <AdminPage />
        // },

        {
          path: '/token',
          element: 
          <div>
            <Token />
            
            </div>
        },

        {
          path: '/admin',
          element: 
          <div>
            <Sidebar />
            
          </div>
        },

        {
          path: '/dashboard',
          element: <Dashboard />
        },

        {
          path: '/promo',
          element: <PromoList />
        },
        {
          path: '/bookings_reservations',
          element: <div>
                    
                    <ReservationForm />
                    
                   <SetMeals />
                   {/* <Gallery2 /> */}
                   <Footer/>
                  </div> 
        }
      ]
    }
   
  ]);

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
    <RouterProvider router={router} />

);

reportWebVitals();

