// const func = ({reservation, index, completed}) => {};
// const func = props => {}; /// props = {}
import constants from "../constants";

const styles = {
  textNormal : {
    textDecoration: 'none'
  },
  textStrikeThrough: {
    textDecoration: 'line-through'
  }
}

const ReservationListItem = ({reservation, deleteReservation, completeOrRestoreReservation, editReservation}) => {
  if (!reservation) return null;

  
  
  
  
  return (
    <>

      
      <tr className="fw-normal " key={reservation.id}>

      <td className="align-middle" style={{padding: '0px 5px'}}>
          <div className="d-flex justify-content-between px-3">
            
          <a 
            href="#!"  
              data-mdb-toggle="tooltip" 
              title="Select this to edit"
            // data-mdb-toggle="modal" data-mdb-target="#reservationEdit"
            onClick={() => {
            editReservation(reservation)
            // handleShow();
              }}>
              <i className="bi bi-pencil-square text-warning  me-3">
              </i>
              </a>
            <a href="#!" 
              data-mdb-toggle="tooltip" 
              title="Completed or Active"
              onClick={() => completeOrRestoreReservation(reservation)}>
                <i className={`${reservation.completed ? 'bi bi-arrow-repeat' : 'fas fa-check text-success' } me-3`}>
                </i>
                </a>
          
            <a 
            href="#!" 
            data-mdb-toggle="tooltip" 
            title="Delete this"
            onClick={() => deleteReservation(reservation.id)}
            >
            
            <i
              className="fas fa-trash-alt text-danger me-3"></i></a>
          </div>
        </td>
      
        <td>
         <span className="p__opensans2">{reservation.FullName}</span>
        </td>
        <td className="p__opensans2">
          <span>{reservation.Date}</span>
        </td>
        <td className="p__opensans2">
          <span>{reservation.Time}</span>
        </td>
        <td className="p__opensans2">
          <span>{reservation.EmailAddress}</span>
        </td>
        <td className="p__opensans2">
          <span>{reservation.ContactNumber}</span>
        </td>
        <td className="p__opensans2">
          <span>{reservation.PartySize}</span>
        </td>
        <td className="p__opensans2">
          <span>{reservation.PreorderedSetMeal}</span>
        </td>
        <td className="p__opensans2">
          <span>{reservation.Occasion}</span>
        </td>
        <td className="p__opensans2">
          <span>{reservation.Message}</span>
        </td>
       
        
        {/* <td className="p__opensans2">
          <h6 className="mb-0"><span className="badge bg-primary">{reservation.Status}</span></h6>
        </td> */}
        
        
      </tr>



    </>
  );
};

export default ReservationListItem;