import './ReservationList.css';
import ReservationListItem from './ReservationListItem';
import React, { useEffect, useState } from 'react';
import constants from '../constants';
import { useNavigate } from "react-router-dom";
import ReservationCreate from './ReservationCreate';
import ReservationSearch from './ReservationSearch';
import ReservationEdit from './ReservationEdit';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';





const ReservationList = () => {
  // variable
  const [reservations, setReservations] = useState([]);
  const [selectedReservation, setSelectedReservation] = useState({});
  const [finishedCount, setFinishedCount] = useState(0);
  const [unfinishedCount, setUnfinishedCount] = useState(0);
  const [lastsubmitted, setLastsubmitted] = useState({});
  
  // const [priorities, setPriorities] = useState([]);

  const fuzzySearch = function (query) {
    const str = this.toLowerCase();
    let i = 0, n = -1, l;
    query = query.toLowerCase();
    for (; l = query[i++] ;){
      if (!~(n = str.indexOf(l, n + 1))){
          return false;
      };
    };
    return true;
  };
  String.prototype.fuzzySearch = fuzzySearch;

  useEffect(() => {
    // console.log(constants.ENDPOINT);
    fetch(`${constants.ENDPOINT}/api/reservations`, {
      method: 'GET', 
      mode: 'cors'})
      .then(response => response.json())
      .then(data => {
        setReservations(data.data);
      })
      .catch(error => console.error(error));
  }, []);

  // useEffect(() => {
  //   fetch(`${constants.ENDPOINT}/api/reservations/priorities`, {
  //     method: 'GET',
  //     mode: 'cors'
  //   })
  //   .then(response => response.json())
  //   .then(data => {
  //     setPriorities(data.data);
  //   })
  //   .catch(error => console.error(error))
  // }, []);

  useEffect(() => {
    setFinishedCount(reservations.filter(reservation => reservation.completed).length);
    setUnfinishedCount(reservations.filter(reservation => !reservation.completed).length);
  }, [reservations]);

  // helper function
  const getIndexById = (id) => reservations.indexOf(reservations.filter((reservation) => reservation.id === id)[0]);

  // delete reservation: (1) update the parent array, (2) call api to delete on backend
  const deleteReservation = (id) => {

    fetch(`${constants.ENDPOINT}/api/reservations/${id}`, {
      method: 'DELETE', 
      mode: 'cors',
      headers: {
        'Authorization' : `Basic ${btoa('admin:adminpass')}`
      }})
    .then(response => response.json())
    .then(data => {
      if(data.success === true && data.data.recordsAffected === 1) {
        console.log(id);
        // 1. Make your own copy of data
        const newReservations = [...reservations]; //copy [1,2,3]
        // 2. Modify your copy data
        newReservations.splice(getIndexById(id), 1);
        // 3. Replace the original data
        setReservations(newReservations);
        alert('Rerservation deleted succesfully.');
      } else {
        alert(`Unable to delete reservation id=${id}`)
      }
    })
    .catch(error => console.error(error));
  }

  // refresh reservation
  const refreshReservations = () => {
    fetch(`${constants.ENDPOINT}/api/reservations`, {
      method: 'GET',
      mode: 'cors'
    })
    .then(response => response.json())
    .then(data => {
      setReservations([...data.data]);
    })
    .catch(error => console.error(error));
  };

  // toggle completed flag
  const completeOrRestoreReservation = (reservation) => {
    fetch(`${constants.ENDPOINT}/api/reservations/${reservation.id}`, {
      method: 'PUT',
      mode: 'cors', 
      headers: {
        'Content-Type' : 'application/json'
      },
    body: JSON.stringify({
      FullName: reservation.FullName,
      PartySize: reservation.PartySize,
      EmailAddress: reservation.EmailAddress,
      ContactNumber: reservation.ContactNumber, 
      PreorderedSetMeal: reservation.PreorderedSetMeal, 
      Occasion: reservation.Occasion, 
      Message: reservation.Message, 
      Date: reservation.Date, 
      Time: reservation.Time,
      Status: reservation.Status, 
      
      completed: !reservation.completed
    })
  })
  .then(response => response.json())
    .then(data => {
      if(data.success) {
        const newReservations = [...reservations];
        newReservations[getIndexById(reservation.id)].completed = !reservation.completed;
        setReservations(newReservations);
      } else {
        alert('There was an issue completing/restoring this reservation.');
      }
    })
    .catch(error => console.error(error));
  };

  // add reservation
  const addReservation = (reservation) => {
    const data = {
      
      FullName: reservation.FullName,
      Message: reservation.Message,
      PartySize: reservation.PartySize,
      EmailAddress: reservation.EmailAddress,
      ContactNumber: reservation.ContactNumber,
      PreorderedSetMeal: reservation.PreorderedSetMeal,
      Occasion: reservation.Occasion,
      Date: reservation.Date,
      Time: reservation.Time,
      completed: reservation.completed
  }
    fetch(`${constants.ENDPOINT}/api/reservations`, {
      
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        alert('You have successfully made a reservation!');
        const newReservations = [...reservations];
        newReservations.push(data.data);
        setReservations(newReservations);
        setLastsubmitted(data.data);
      } else {
        alert('There was an issue adding this reservation.');
      }
    })
    .catch(error => console.error(error));
  };

   // search reservation
   const searchReservation = (term) => {
    // alert( `Searching for ${term}`)
    const newReservations = [...reservations];
    const result = newReservations.filter(reservation => reservation.FullName.fuzzySearch(term));
    console.log(result);
    setReservations(result);
  };

   // edit 
   const editReservation = (reservation) => {
    const data = {
      FullName: reservation.FullName,
      Message: reservation.Message,
      PartySize: reservation.PartySize,
      EmailAddress: reservation.EmailAddress,
      ContactNumber: reservation.ContactNumber,
      PreorderedSetMeal: reservation.PreorderedSetMeal,
      Occasion: reservation.Occasion,
      Date: reservation.Date,
      Time: reservation.Time,
      completed: reservation.completed
    }
      fetch(`${constants.ENDPOINT}/api/reservations/${reservation.id}`, {
      method: 'PUT',
      mode: 'cors', 
      headers: {
        'Content-Type' : 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      if(data.success) {
        const newReservations = [...reservations];
        newReservations[getIndexById(reservation.id)] = data.data.entity;
        setReservations(newReservations);
        alert('Rerservation edited succesfully.');
        }  else {
          alert('There was an issue editing this reservation.');
        }
      })
      .catch(error => console.error(error));
      
    };




  // styles
  const styles = {
    // body: {
    //   backgroundColor : '#eee'
    // },
    cardBody: {
      position: 'relative',
      height: '300px',
      overflow: 'auto'
    }
  }


  // helpers
  const renderTableHeading = () => {
    return (
      <thead>
        <tr className='p__opensans2'>
        <th scope="col">Actions</th>
        <th scope="col">Full Name</th>
        <th scope="col">Date</th>
        <th scope="col">Time</th>
        <th scope="col">Email Address</th>
        <th scope="col">Contact Number</th>
        <th scope="col">Party Size</th>
        <th scope="col">Preordered Set Meal</th>
        <th scope="col">Occasion</th>
        <th scope="col">Message</th>
        {/* <th scope="col">Status</th> */}
        
        
      </tr>
    </thead>
  );
};

const renderRefreshBtn = () => {
  return (
    <a 
      href="#!" 
      
      onClick={() => refreshReservations()}
      >
        <i className="fa-solid fa-arrows-rotate test-white mx-2"></i>
    </a>
  );
};

const renderReservationListItem = (reservation) => {
  return (<ReservationListItem 
    key={reservation.id}
    reservation={reservation} 
    deleteReservation={deleteReservation}
    editReservation={setSelectedReservation}
    completeOrRestoreReservation={completeOrRestoreReservation}
    
  />);
}





  return (
    <>
      
          <div className="row d-flex justify-content-center align-items-center h-100 ">
            <div >

              <div className="">
                <div className="card-header p-3 bg-dark">
                <div className='d-flex justify-content-between'>
                  <div>
                    <h5 className="mb-0 p__cormorant"><i className="fa-solid fa-clipboard-list me-3 "></i>Reservation List {renderRefreshBtn()}</h5>
                  </div>
                  
                  
                  <div>
                  <ReservationCreate /> <nbsp></nbsp> 
                  <ReservationEdit 
                  reservation={selectedReservation}
                  editReservation={editReservation}/>
                      
                    </div>
                  </div>
                </div>
                <div className="card-header p-3 bg-dark">
                <div className='d-flex justify-content-between'>
                  <div>
                    <h5 className="mb-0 p__opensans"><i className=" me-2"></i>Active Reservations ({unfinishedCount})</h5>
                  </div>
                  <div>
                    <ReservationSearch searchReservation={searchReservation} refreshReservations={refreshReservations} />
                  </div>
                  </div>
                </div>
                
                <div className="bg-light" data-mdb-perfect-scrollbar="true" style={styles.cardBody}>
                 
                  {/* Incomplete Reservations */}
                  {unfinishedCount <= 0 ? 
                  (<p className="text-center">No items to show</p>) 
                  : 
                  (<MDBTable striped>
                      {renderTableHeading()}
                      <tbody>
                        {
                          reservations
                            .filter(reservation => !reservation.completed)
                            .map(reservation => renderReservationListItem(reservation))
                        }
                      </tbody>
                    </MDBTable>
                  )}
                </div>

                <div className="card-header p-3 bg-dark">
                <div className='d-flex justify-content-between'>
                  <div>
                    <h5 className="mb-0 p__opensans"><i className=" me-2"></i>Completed Reservations ({finishedCount})</h5>
                  </div>
                  <div>
                  <ReservationEdit 
                  reservation={selectedReservation}
                  editReservation={editReservation}/>
                  </div>
                  </div>
                  
                </div>
                
                <div className="bg-light" data-mdb-perfect-scrollbar="true" style={styles.cardBody}>
                  
                  {/* Completed Reservations */}
                  

                  {finishedCount <= 0 ? (<p className="text-center">No items to show</p>) : 
                  (<MDBTable striped>
                      {renderTableHeading()}
                      <tbody >
                        {
                          reservations
                            .filter(reservation => reservation.completed)
                            .map(reservation => renderReservationListItem(reservation))
                        }
                      </tbody>
                    </MDBTable>
                  )}

                </div>
                
                
              </div>

            </div>
          </div>
        
      
    </>
  );

  // unreachable
};

export default ReservationList;