import React from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from "react";
import { Container, Col, Row, Form, Button, Carousel, } from "react-bootstrap";
import './Form.css';
import '../../../src/App.css';
import constants from '../../components/constants';
import { SubHeading } from "../../components";
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


const ReservationForm = (reservation) => {
    const [FullName, setFullName] = useState('');
    const [PartySize, setPartySize] = useState('');
    const [EmailAddress, setEmailAddress] = useState('');
    const [ContactNumber, setContactNumber] = useState('');
    const [Date, setDate] = useState('');
    const [Time, setTime] = useState('');
    const [Message, setMessage] = useState('');
    const [PreorderedSetMeal, setPreorderedSetMeal] = useState('');
    const [Occasion, setOccasion] = useState('');
    const [reservations, setReservations] = useState([]);
    const [lastsubmitted, setLastsubmitted] = useState({});

     
    // = useState({fullname: "", partySize: "", email: "", email: "", contactnumber: "", preOrderedMeal: "", occasion: "", message: "" });

   
  function SubmitButton(){
    if (FullName && PartySize && EmailAddress &&  ContactNumber && Date && Time)
  {return
    <Button className="custom__button" type="submit"onClick={handleShow} >
      Submit</Button>
  } 
  else 
  {
    return 
    <Button className="custom__button" type="submit" id=""  
    onClick={handleShow} 
    disabled
    >Submit
    </Button>
  };
    
  };
  


    const GetTokenID = (reservation) => {
      fetch(`${constants.ENDPOINT}/api/reservations/${reservation.id}`, {
        method: 'GET',
        mode: 'cors', 
        headers: {
          'Content-Type' : 'application/json'
        },
      body: JSON.stringify({
        id:reservation.id,
        
      })
    })
    .then(response => response.json())
      .then(data => {
        if(data.success) {
          const newReservations = [...reservations];
          newReservations[getIndexById(reservation.id)].completed = !reservation.completed;
          setReservations(newReservations);
        } else {
          alert('There was an issue completing/restoring this reservation.');
        }
      })
      .catch(error => console.error(error));
    };
  
  const addReservation = (reservation) => {
    fetch(`${constants.ENDPOINT}/api/reservations`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(reservation)
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        const newReservations = [...reservations];
        newReservations.push(data.data);
        setReservations(newReservations);
        setLastsubmitted(data.data);
        
      } else {
        alert('There was an issue adding this reservation.');
      }
    })
    .catch(error => console.error(error));
  };  

  // const RenderTokenID = (reservation) => {
  //   return (<RetrieveTokenID 
  //     key={reservation.id}
  //     reservation={reservation}
  //     GetTokenID={GetTokenID}
  //     // priorities={priorities}
  //   );
  // }
  
// helper function
const getIndexById = (id) => reservations.indexOf(reservations.filter((reservation) => reservation.id === id)[0]);


const handleSubmit = (e) => {
    e.preventDefault(); // dont refresh the action
   
    const data = {
      
      FullName: FullName,
      Message: Message,
      PartySize: PartySize,
      EmailAddress: EmailAddress,
      ContactNumber: ContactNumber,
      PreorderedSetMeal: PreorderedSetMeal,
      Occasion: Occasion,
      Date: Date,
      Time: Time,
      completed: false
    };
    console.log(data);
    addReservation(data);
    
    setFullName('');
    setPartySize('');
    setEmailAddress('');
    setContactNumber('');
    setPreorderedSetMeal('');
    setOccasion('');
    setMessage('');
    setDate('');
    setTime('');
    //show success modal
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [key, setKey] = useState('home');

  

    return ( 
      <>
      
      <div className="formbg">
          <Row className="container-fluid">
            {/* <Col className="promo_poster d-flex justify-content-center align-items-center">
              <Container>
                    <Carousel className="carousel">
                        <Carousel.Item interval={4000} className="carousel_item">
                            <img className="carousel_item" src={poster01} alt="" />
                              <Carousel.Caption>
                            
                              </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item interval={4000} className="carousel_item">
                            <img className="carousel_item" src={poster02} alt="" />
                              <Carousel.Caption>
                            
                              </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item interval={4000} className="carousel_item">
                            <img className="carousel_item" src={poster01} alt="" />
                              <Carousel.Caption>
                            
                              </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item interval={4000} className="carousel_item">
                            <img className="carousel_item" src={poster03} alt="" />
                              <Carousel.Caption>
                            
                              </Carousel.Caption>
                        </Carousel.Item>
                </Carousel>
            </Container>
            </Col> */}

            <Col>
                <Container className="reservation_form">
                <div className="app__specialMenu-title">
                    
                    
      <Tabs
        defaultActiveKey="home"
        id="fill-tab-example"
        className="mb-3 justify-content-center formtab p__cormorant text-capitalize "
        

        >
      <Tab eventKey="home" title="Reservation">
      
      <h1 className="headtext__cormorant">Make A Reservation</h1>
      <SubHeading title="Fill-up Form" />
      <Form method='post'  onSubmit={handleSubmit}>
                                <Row className="mb-3 ">
                                    <Form.Group controlId="full_name">
                                        <Form.Label className="p__opensans justify-content-start ">Full Name</Form.Label>
                                            <Form.Control type="text" id="" 
                                            onChange={(e) => {
                                              setFullName(e.target.value);
                                            }} value={FullName} 
                                            required
                                            placeholder="Enter Your Full Name..." />
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} className="mb-3" controlId="email_address">
                                            <Form.Label className="p__opensans">Email Address</Form.Label>
                                                <Form.Control type="email"  id="" value={EmailAddress} 
                                                onChange={(e) => {
                                                setEmailAddress(e.target.value);
                                                }}
                                                placeholder="Ex. youremail@gmail.com" required/>
                                    </Form.Group>
                                    
                                    <Form.Group as={Col} controlId="contact_number">
                                        <Form.Label className="p__opensans">Contact Number</Form.Label>
                                              <Form.Control type="text" id="" value={ContactNumber} 
                                                  onChange={(e) => {
                                                    setContactNumber(e.target.value);
                                                  }} placeholder="Ex. 0929455033"/>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} className="mb-3" controlId="party_size">
                                        <Form.Label className="p__opensans">Party Size</Form.Label>
                                            <Form.Control type="text" id="" value={PartySize} 
                                            onChange={(e) => {
                                              setPartySize(e.target.value);
                                            }} 
                                            placeholder="Ex. 3" required/>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="preodered_Set_Meal">
                                        <Form.Label className="p__opensans" >Preorder a Set Meal(optional):</Form.Label>
                                                <Form.Select value={PreorderedSetMeal} 
                                                      onChange={(e) => {
                                                        setPreorderedSetMeal(e.target.value);
                                                      }} defaultValue="">
                                                      <option>None</option>
                                                      <option>Meat Lover</option>
                                                      <option>SeaFood Lover</option>
                                                      <option>Doubled</option>
                                                      <option>Combo</option>
                                                </Form.Select>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="Occasion">
                                        <Form.Label className="p__opensans">Occasion(optional):</Form.Label>
                                            <Form.Select value={Occasion} 
                                                      onChange={(e) => {
                                                        setOccasion(e.target.value);
                                                      }} defaultValue="">
                                                      <option>None</option>
                                                      <option>Breakfast</option>
                                                      <option>Lunch</option>
                                                      <option>Dinner</option>
                                                      <option>Birthday</option>
                                                      <option>Meeting</option>
                                                      <option>Romantic Date</option>
                                                      <option>Wedding Anniversary</option>
                                            </Form.Select>
                                    </Form.Group>
                                </Row>
                                
                                <Row className="mb-3 ">
                                <Form.Group controlId="Message">
                                        <Form.Label className="p__opensans">Additional Request or Service</Form.Label>
                                        <Form.Control as="textarea" rows={3}
                                        value={Message} 
                                        onChange={(e) => {
                                        setMessage(e.target.value);
                                        }} placeholder="Additional Request or Service" autoFocus />
                                </Form.Group>
                                </Row>
                                
                                
                                <Row className="mb-3">
                                <Form.Group as={Col} controlId="Date">
                                    <Form.Label className="p__opensans">Date:</Form.Label>
                                    <Form.Control type="date" 
                                    className="form-control" 
                                    autoFocus
                                    required
                                    value={Date} 
                                    onChange={(e) => {
                                      setDate(e.target.value);
                                    }}  />
                                </Form.Group>
                                <Form.Group as={Col} controlId="Time">
                                        <Form.Label className="p__opensans" >Time:</Form.Label>
                                                <Form.Select autoFocus value={Time} 
                                                      required
                                                      onChange={(e) => {
                                                        setTime(e.target.value);
                                                      }} >
                                                      <option defaultValue={null}>Choose..</option>
                                                      <option>8:00 AM</option>
                                                      <option>10:00 AM</option>
                                                      <option>12:00 PM</option>
                                                      <option>2:00 PM</option>
                                                      <option>4:00 PM</option>
                                                      <option>6:00 PM</option>
                                                      <option>8:00 PM</option>
                                                      <option>9:30 PM</option>
                                                </Form.Select>
                                    </Form.Group>
                                    </Row>
                                  <br></br>
                                  
                                <row className="d-flex mb-3 justify-content-center p__opensans">      
                                  <SubmitButton></SubmitButton>
                                  <Button className="custom__button" type="submit"onClick={handleShow} >
      Submit</Button>
                                </row>  
                                <br></br> 
                        </Form>
      </Tab>
            
      
      
      
      <Tab eventKey="profile" title="Booking">
      <h1 className="headtext__cormorant">Book A Service</h1>
      <SubHeading title="Fill-up Form" />
        Bookings will become available soon.
      </Tab>
      
    </Tabs>
                      </div>
                        
                </Container>
            </Col>
          </Row> 
          <br />
          <br />   
        </div>

        <Modal className="center-modal" show={show} onHide={handleClose} key={reservation.id}>
      
      
        
        <Modal.Body className="sidebarbg" closeButton>
        {/* <Modal.Header  > */}
          {/* <Modal.Title></Modal.Title> */}
        {/* </Modal.Header> */}
              <h1 className="headtext__cormorant text-center">Thank you, </h1>
              <br></br>
              <h1 className="p__cormorant text-center">{lastsubmitted.FullName}!</h1>
              <br></br>
              <h3 className="p__cormorant text-center">Your resevation has been successfully added.</h3>
              <br></br>
              <h3 className="p__cormorant text-center">Please save your Reference ID:</h3>
              <hr></hr>
              
               <p className="p__opensans text-center">{lastsubmitted.id}</p>
               
              
            </Modal.Body>
        
        
      </Modal>



        </>
     );
}
 
export default ReservationForm;