import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Container, Col, Row, Form, Button } from "react-bootstrap";


const ReservationEdit = ({reservation, editReservation}) => {

    const [FullName, setFullName] = useState('');
    const [PartySize, setPartySize] = useState('');
    const [EmailAddress, setEmailAddress] = useState('');
    const [ContactNumber, setContactNumber] = useState('');
    const [Date, setDate] = useState('');
    const [Time, setTime] = useState('');
    const [Message, setMessage] = useState('');
    const [PreorderedSetMeal, setPreorderedSetMeal] = useState('');
    const [Occasion, setOccasion] = useState('');
    const [completed, setCompleted] = useState(false);
    // const [reservations, setReservations] = useState([]);
  
  

  const handleSubmit = (e) => {
    e.preventDefault(); // dont refresh the action
   
    const data = {
      id: reservation.id,
      FullName: FullName,
      Message: Message,
      PartySize: PartySize,
      EmailAddress: EmailAddress,
      ContactNumber: ContactNumber,
      PreorderedSetMeal: PreorderedSetMeal,
      Occasion: Occasion,
      Date: Date,
      Time: Time,
      completed: completed
    };
    console.log(data);
    editReservation(data);
    // setFullName('');
    // setPartySize('');
    // setEmailAddress('');
    // setContactNumber('');
    // setPreorderedSetMeal('');
    // setOccasion('');
    // setMessage('');
    // setDate('');
    // setTime('');
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  // const renderEditForm = (reservation) =>{
  //   return (
      
  //   );
  // }


  return (
    <>

<Button className="custom__button" onClick={handleShow}>
        Edit Selected
        <i className="bi bi-pencil-square px-2"> </i>
      </Button>

    <Modal className="center-modal" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
      <Modal.Title>
          <h5 className="modal-title" id="reservationEditLabel">Currently Editing: {reservation.FullName}</h5>
          </Modal.Title>
          
          </Modal.Header>
            
          <Form method='post' className="bg-dark"  onSubmit={handleSubmit}>
      <Modal.Body>
                        <Row className="mb-3 ">
                            <Form.Group controlId="first_name">
                                <Form.Label className="p__opensans">Full Name</Form.Label>
                                    <Form.Control type="text" id="name" 
                                    // value={Name}
                                    defaultValue={reservation.FullName}
                                    onChange={(e) => {
                                      setFullName(e.target.value);
                                    }}  placeholder=""
                                    
                                    autoFocus/>
                            </Form.Group>
    
                            
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} className="" controlId="email_address">
                                    <Form.Label className="p__opensans">Email Address</Form.Label>
                                        <Form.Control type="email"  id="" 
                                        // value={EmailAddress} 
                                        defaultValue={reservation.EmailAddress}
                                        onChange={(e) => {
                                        setEmailAddress(e.target.value);
                                        }}
                                        placeholder={reservation.EmailAddress} 
                                        
                                        autoFocus />
                            </Form.Group>
                            
                            <Form.Group as={Col} controlId="contact_number">
                                <Form.Label className="p__opensans">Contact Number</Form.Label>
                                      <Form.Control type="text" id="" 
                                      // value={ContactNumber} 
                                      defaultValue={reservation.ContactNumber}
                                          onChange={(e) => {
                                            setContactNumber(e.target.value);
                                          }} placeholder={reservation.ContactNumber} 
                                          
                                          autoFocus/>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} className="mb-3" controlId="party_size">
                                <Form.Label className="p__opensans">Party Size</Form.Label>
                                    <Form.Control type="text" id="" 
                                    // value={PartySize} 
                                    defaultValue={reservation.PartySize}
                                    onChange={(e) => {
                                      setPartySize(e.target.value);
                                    }} 
                                    
                                    placeholder={reservation.PartySize} autoFocus/>
                            </Form.Group>
                              <Form.Group as={Col} controlId="Occasion">
                                <Form.Label className="p__opensans">Occasion(optional):</Form.Label>
                                    <Form.Select autoFocus defaultValue={reservation.Occasion}
                                    // value={Occasion} 
                                              onChange={(e) => {
                                                setOccasion(e.target.value);
                                              }} >
                                              <option>None</option>
                                              <option>Breakfast</option>
                                              <option>Lunch</option>
                                              <option>Dinner</option>
                                              <option>Birthday</option>
                                              <option>Meeting</option>
                                              <option>Romantic Date</option>
                                              <option>Wedding Anniversary</option>
                                    </Form.Select>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3 ">
                        <Form.Group as={Col} controlId="preodered_Set_Meal">
                                <Form.Label className="p__opensans" >Preorder a Set Meal(optional):</Form.Label>
                                        <Form.Select autoFocus 
                                        // value={PreorderedSetMeal} 
                                        defaultValue={reservation.PreorderedSetMeal}
                                              onChange={(e) => {
                                                setPreorderedSetMeal(e.target.value);
                                              }} >
                                              <option>None</option>
                                              <option>Meat Lover</option>
                                              <option>SeaFood Lover</option>
                                              <option>Doubled</option>
                                              <option>Combo</option>
                                        </Form.Select>
                            </Form.Group>
                          </Row>
                          <Row className="mb-3 ">
                        <Form.Group controlId="Message">
                                <Form.Label className="p__opensans">Additional Request or Service</Form.Label>
                                <Form.Control as="textarea" rows={3}
                                // value={Message} 
                                defaultValue={reservation.Message}
                                onChange={(e) => {
                                setMessage(e.target.value);
                                }} placeholder={reservation.Message} 
                                 autoFocus />
                        </Form.Group>
                        </Row>
                        
                        {/* <div className="mt-4">
  <label className="form-label" htmlFor="description">Completed</label>
  <div className="btn-group ms-3">
    <input 
      type="radio" 
      className="btn-check" 
      name="options" id="completedYes" 
      checked={completed} 
      onChange={(e) => {
        setCompleted(e.target.id === 'completedYes')}}
    />
    <label className="btn btn-secondary" htmlFor="completedYes">YES</label>

    <input 
      type="radio" 
      className="btn-check" 
      name="options" 
      id="completedNo" 
      checked={!completed} 
      onChange={(e) => {
        setCompleted(e.target.id === 'completedYes')}}
    />
    <label className="btn btn-secondary" htmlFor="completedNo">NO</label>
  </div>
</div> */}
                        <Row className="mb-3">
                        <Form.Group as={Col} controlId="Date">
                            <Form.Label className="p__opensans">Date:</Form.Label>
                            <Form.Control type="date" 
                            className="form-control" 
                            autoFocus
                            // value={Date} 
                            defaultValue={reservation.Date}
                            onChange={(e) => {
                              setDate(e.target.value);
                            }}  />
                        </Form.Group>
                        <Form.Group as={Col} controlId="Time">
                                <Form.Label className="p__opensans" >Time:</Form.Label>
                                        <Form.Select autoFocus 
                                        // value={Time} 
                                        defaultValue={reservation.Time}
                                              onChange={(e) => {
                                                setTime(e.target.value);
                                              }}   >
                                              <option>8:00 AM</option>
                                              <option>10:00 AM</option>
                                              <option>12:00 PM</option>
                                              <option>2:00 PM</option>
                                              <option>4:00 PM</option>
                                              <option>6:00 PM</option>
                                              <option>8:00 PM</option>
                                              <option>9:30 PM</option>
                                        </Form.Select>
                            </Form.Group>
                            </Row>
                          <br></br>
                          </Modal.Body>
                          <Modal.Footer>
                        <row className="d-flex mb-3 justify-content-center p__opensans">      
                          <Button className="custom__button"  type="submit" id="" onClick={handleClose} >
                              Submit
                          </Button>
                        </row>  
                        </Modal.Footer>
                        <br></br> 
                </Form>


            {/* {
                renderEditForm(reservation)
            } */}
      </Modal>


    </>
  );

};

export default ReservationEdit;