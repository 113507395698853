import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Chef.css';

const Chef = () => (
  <div className="app__bg app__wrapper section__padding">
    <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.chef} alt="chef_image" />
    </div>
    <div className="app__wrapper_info">
      <SubHeading title="About Chef Mac" />
      <h1 className="headtext__cormorant">My Motivations & Inspirations</h1>

      <div className="app__chef-content">
        <div className="app__chef-content_quote">
          <img src={images.quote} alt="quote_image" />
          <p className="p__opensans">Every great dish starts with a spark of passion, a pinch of creativity, and a sprinkle of love.</p>
        </div>
        <br></br>
        <p className="p__opensans"> Macartney Mallari Ancheta, grew up in Balik balik Manila. After he graduated, he applied as a cook in a fine dining restaurant and got his promotion as an acting manager. His dreams didn’t stop there, he dreamed to serve more people through his cooking. He then applied as assistant cook in a large cruise ship who serves American and European. Due to his excellence and perseverance, he got promoted as a Chef Tournant, and finally, as a Head Cook </p>
      </div>

      <div className="app__chef-sign">
        <p>Macartney Ancheta</p>
        <p className="p__opensans">Chef & Founder</p>
        <img src={images.sign} alt="sign_image" />
      </div>
    </div>
  </div>
);

export default Chef;
