import './Gallery.css';
import PromoListItem from './GalleryItem';
import React, { useEffect, useState } from 'react';
import constants from '../../components/constants';
import { BsInstagram, BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';

import { SubHeading } from '../../components';




const PromoList = () => {
  // variable
  const [promos, setPromos] = useState([]);
  const [finishedCount, setFinishedCount] = useState(0);
  const [unfinishedCount, setUnfinishedCount] = useState(0);

  useEffect(() => {
    // console.log(constants.ENDPOINT);
    fetch(`${constants.ENDPOINT}/api/promos`, {method: 'GET', mode: 'cors'})
      .then(response => response.json())
      .then(data => {
        setPromos(data.data);
      })
      .catch(error => console.error(error));
  }, []);


  useEffect(() => {
    setFinishedCount(promos.filter(promo => promo.activity).length);
    setUnfinishedCount(promos.filter(promo => !promo.activity).length);
  }, [promos]);

  // helper function
  const getIndexById = (id) => promos.indexOf(promos.filter((promo) => promo.id === id)[0]);

  // delete promo: (1) update the parent array, (2) call api to delete on backend
  const deletePromo = (id) => {

    fetch(`${constants.ENDPOINT}/api/promos/${id}`, {
      method: 'DELETE', 
      mode: 'cors',
      headers: {
        'Authorization' : `Basic ${btoa('admin:adminpass')}`
      }})
    .then(response => response.json())
    .then(data => {
      if(data.success === true && data.data.recordsAffected === 1) {
        console.log(id);
        // 1. Make your own copy of data
        const newPromos = [...promos]; //copy [1,2,3]
        // 2. Modify your copy data
        newPromos.splice(getIndexById(id), 1);
        // 3. Replace the original data
        setPromos(newPromos);
      } else {
        alert(`Unable to delete promo id=${id}`)
      }
    })
    .catch(error => console.error(error));
  }

  // refresh promo
  const refreshPromos = () => {
    fetch(`${constants.ENDPOINT}/api/promos`, {
      method: 'GET',
      mode: 'cors'
    })
    .then(response => response.json())
    .then(data => {
      setPromos([...data.data]);
    })
    .catch(error => console.error(error));
  };

  // toggle activity flag
  const completeOrRestorePromo = (promo) => {
    fetch(`${constants.ENDPOINT}/api/promos/${promo.id}`, {
      method: 'PUT',
      mode: 'cors', 
      headers: {
        'Content-Type' : 'application/json'
      },
      body: JSON.stringify({
        promoname: promo.promoname,
        description: promo.description,
        activity: !promo.activity
      })
    })
    .then(response => response.json())
    .then(data => {
      if(data.success) {
        const newPromos = [...promos];
        newPromos[getIndexById(promo.id)].activity = !promo.activity;
        setPromos(newPromos);
      } else {
        alert('There was an issue completing/restoring this promo.');
      }
    })
    .catch(error => console.error(error));
  };

// add promo
  const addPromo = (promo) => {
    fetch(`${constants.ENDPOINT}/api/promos`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(promo)
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        const newPromos = [...promos];
        newPromos.push(data.data);
        setPromos(newPromos);
      } else {
        alert('There was an issue adding this promo.');
      }
    })
    .catch(error => console.error(error));
  };  

  // styles
  const styles = {
    body: {
      backgroundColor : '#eee'
    },
    cardBody: {
      position: 'relative',
      height: '400px',
      overflow: 'auto'
    }
  }

  // helpers
  const renderTableHeading = () => {
    return (
      <thead>
        <tr>
          <th scope="col">Team Member</th>
          <th scope="col">Promo</th>
          <th scope="col"></th>
          
        </tr>
      </thead>
    );
  };

  const renderRefreshBtn = () => {
    return (
      <a 
        href="#!" 
        className='btn-primary'
        onClick={() => refreshPromos()}
        >
          <i className="fa-solid fa-arrows-rotate test-white"></i>
      </a>
    );
  };

  const renderPromoListItem = (promo) => {
    return (<PromoListItem 
      key={promo.id}
      promo={promo} 
      deletePromo={deletePromo}
      completeOrRestorePromo={completeOrRestorePromo}
    />);
  }


  //scroll
        const scrollRef = React.useRef(null);

        const scroll = (direction) => {
          const { current } = scrollRef;

          if (direction === 'left') {
            current.scrollLeft -= 300;
          } else {
            current.scrollLeft += 300;
          }
        };




  return (
    
       <>

<div className="app__gallery flex__center">
      <div className="app__gallery-content">
        <SubHeading title="Latest News & Promotions" />
        <h1 className="headtext__cormorant">Exciting News! <br></br> Check Out Our Irresistible Promos!</h1>
        <p className="p__opensans" style={{ color: '#AAAAAA', marginTop: '2rem' }}>Dear Pasta Lovers, Ready for an extraordinary dining experience? Our restaurant has got you covered with unbeatable promos that will tantalize your taste buds like never before!</p>
        {renderRefreshBtn()}
      </div>
      <div className="app__gallery-images">
        <div className="app__gallery-images_container" ref={scrollRef}>
                {finishedCount <= 0 ? 
                  (
                    <p className="text-center">No items to show</p>
                  ) : 
                  (
                    <>{
                          promos
                            .filter(promo => promo.activity)
                            .map(promo => renderPromoListItem(promo))
                        }
                      
                    </>
                  )}
        </div>
        <div className="app__gallery-images_arrows">
          <BsArrowLeftShort className="gallery__arrow-icon" onClick={() => scroll('left')} />
          <BsArrowRightShort className="gallery__arrow-icon" onClick={() => scroll('right')} />
        </div>
      </div>
    </div>

        {/* main section */}

     
        

        {/* main section */}
    
        </>
  );

  // unreachable
};

export default PromoList;