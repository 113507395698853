import React from 'react';
import MainMenu from '../../MainMenu';
import './Menu2Overlay.css';

const Menu2Overlay = () => (
  <div className="app__footerOverlay">
    <div className="app__footerOverlay-black" />
    <div className="app__footerOverlay-img app__bg" />
    
  
  </div>
);

export default Menu2Overlay;
